import {Files} from "../svg/Files";
import {PencilSquare} from "../svg/PencilSquare";
import {Bin} from "../svg/Bin";
import { useDispatch, useSelector } from "react-redux";
import { removeFromBasket, setActiveTabDetails, setDetailsDataset, setDetailsDatasetOffline } from "../../actions";
import { DOWNLOAD_DATA } from "../../constDefinition";
import { Link } from "react-router-dom";
import ViewOrder from "../modal/ViewOrder";

export const ProductItemHeader = (props) => {
    const dispatch = useDispatch()
    let indexOrder = props.indexOrder
    let order = useSelector(state => state.orders)
    const path = window.location.pathname
    console.log(order.orders.length)
    if(order.orders.length > 0){
        var orderType = order.orders[indexOrder] ? order.orders[indexOrder].items[props.index].selection.provisioning : '';
        var orderName = order.orders[indexOrder] ? order.orders[indexOrder].items[props.index].requestName : '';
    }
    const location = props.header;
    var index = props.index
    return(
        <div className="basket-item__header">
            {
                path == '/order-details' ?
                    order && orderType == 'api' ? <ViewOrder orderType={orderType} orderName={orderName}/> : ''
                : ''
            }
            {location === 'order-details' ?
            <div>

                <Link to="/details">
                    <div onClick={() => {
                        dispatch(setActiveTabDetails(DOWNLOAD_DATA))
                        dispatch(setDetailsDatasetOffline(props.item.dataset,props.item.selection))
                        }}>
                        <PencilSquare />
                    </div>
                </Link>
            </div>
            :
            <div onClick={() => dispatch(removeFromBasket(index))}>
            

                <Bin />
            </div>}
        </div>
    )
}