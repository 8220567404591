import RightSidebar from "../right-sidebar/RightSidebar";
import LoginBanner from "../item-components/LoginBanner"
import { useSelector } from "react-redux";
function DetailsSidebar(props) {

    var isLogged = useSelector(state => state.isLogged.logged)
    let sidebarSections = [
        {title : 'Datasets'},
        {title : 'Licences'},
        {title : 'Publications'},
    ]

    return (
        <div className="col-md-4" style={{paddingLeft : 25+ "px"}}>
            {!isLogged && <LoginBanner />}
            <RightSidebar sidebarSections={sidebarSections}/>
        </div>
    )

};

export default DetailsSidebar;