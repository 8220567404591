import './Card.css'

function DetailsMain(props) {


    return (
        <div className="card">
            {props.children}
        </div>
    )

};

export default DetailsMain;