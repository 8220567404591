import React from 'react';
import Flickity from 'react-flickity-component'
import "flickity/dist/flickity.css";
import CarouselCard from "./CarouselCard";
import './Carousel.css'



function Carousel(props){

    let carouselCard = props.dataCarousel.map( (el, i) => <CarouselCard key={el.id} title={el.title} item={props.dataCarousel[i]}/> );
    return(
        <Flickity
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={props.flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
        >
            {carouselCard}
        </Flickity>
    )
}

export default Carousel