import { OVERVIEW, SET_DETAILS_DATASET, ACTIVE_TAB_DETAILS, SET_DETAILS_DATASET_RELATED, SET_DETAILS_DATASET_SELECTION } from "../constDefinition";

const initial_state = {
    dataset: null,
    datasetDetailsActiveTab: OVERVIEW,
    relatedDatasets: [],
    selection: {}
}

const datasetDetailsReducer = (state = initial_state, action) => {
    switch(action.type) {
        case SET_DETAILS_DATASET_SELECTION:
            state.dataset = action.value
            state.selection = action.selection
            return state;
        case SET_DETAILS_DATASET:
            state.dataset = action.value
            return state;
        case ACTIVE_TAB_DETAILS:
            state.datasetDetailsActiveTab = action.value
            return state;
        case SET_DETAILS_DATASET_RELATED:
            state.relatedDatasets = action.results
        default:
            return state;
    }
}

export default datasetDetailsReducer;