import isLoggedReducer from './isLogged';
import realTimeFormReducer from './realTimeForm';
import archiveFormReducer from './archiveForm';
import activeTabReducer from './activeTab';
import datasetDetailsReducer from './datasetDetails';
import resultVisualizationReducer from './resultVisualization'
import ordersReducer from './orders'

import {combineReducers} from 'redux';

export const rootReducer = combineReducers({
    isLogged: isLoggedReducer,
    realTimeForm: realTimeFormReducer,
    archiveForm: archiveFormReducer,
    activeTab: activeTabReducer,
    datasetDetails: datasetDetailsReducer,
    resultVisualization : resultVisualizationReducer,
    orders: ordersReducer
})

export default rootReducer;