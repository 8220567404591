import React from 'react';
import "flickity/dist/flickity.css";
import Card from "../results/Card"
import './CarouselCard.css'


function CarouselCard(props){
    return(

        <div className="carousel-card">
            <Card item={props.item}></Card>
        </div>
    )
}

export default CarouselCard;
