import React from 'react'
import { Button, Image, Modal } from 'semantic-ui-react'
import {Link} from "react-router-dom";
import { submitOrder } from '../../actions';
import { useDispatch } from 'react-redux';

function RequestProcessed(props) {
    const [open, setOpen] = React.useState(false)
    const dispatch = useDispatch();

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                var filledForm = {}
                filledForm = Object.assign(filledForm, props.form)
                filledForm["provisioning"] = props.item.delivery[props.provisioning]
                filledForm["encoding"] = props.item.encoding[props.encoding]
                if(props.requestName === ''){
                    props.fieldValidation()
                    return
                }
                setOpen(true)
                dispatch(submitOrder(props.requestName, [{'requestName': props.requestName, 'dataset': props.item, 'selection': filledForm, 'version': props.version}], "In Progress"))
            }}
            open={open}
            trigger={<Button className={props.isLogged ? "primary" : "disabled"}>{props.title}</Button>}
            className={'p-5'}
        >
            <Modal.Header className={'border-none px-0 mt-3'}><h1>Thank you, your request is being processed</h1></Modal.Header>
            <Modal.Content className={'px-0'}>
                <Modal.Description>
                    <p><strong>You can follow the status in the “Orders” area</strong></p>
                </Modal.Description>
            </Modal.Content>
            <div className="ms-auto mb-2">
                <Modal.Actions>
                    <Link to="/orders">
                        <Button primary className={'m-0'} onClick={() => setOpen(false)}>Go to your orders</Button>
                    </Link>
                </Modal.Actions>
            </div>
        </Modal>
    )
}

export default RequestProcessed;