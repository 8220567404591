import React from 'react'
import { Search } from 'semantic-ui-react'
import { baseURL } from '../../services/constDefinitionService';
import axios from 'axios'
import { addParametersArchive, addParametersRealTime } from '../../actions';
import { ARCHIVE } from '../../constDefinition';
import { useDispatch } from 'react-redux';



const initialState = {
  loading: false,
  results: [],
  value: '',
}
var form

function SearchReducer(state, action) {
  const dispatchGlobal = useDispatch()
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      form == ARCHIVE ? dispatchGlobal(addParametersArchive(action.selection)) : dispatchGlobal(addParametersRealTime(action.selection))
      return { ...state, value: '' }

    default:
      throw new Error()
  }
}

const resultRenderer = ({ shortname, name }) => {
  return (
  <div className="row">
    <h3 className="col-2"><b>{shortname}</b></h3>
    <h3 className="col-10">{name}</h3>
  </div>
  )
}

function SearchParameters(props) {
  let cancel
  form = props.form
  const [state, dispatch] = React.useReducer(SearchReducer, initialState)
  const { loading, results, value } = state

  const timeoutRef = React.useRef()
  const handleSearchChange = React.useCallback((e, data) => {
    clearTimeout(timeoutRef.current)
    dispatch({ type: 'START_SEARCH', query: data.value })

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' })
        return
      }

      axios({
        method: 'GET',
        url: baseURL + 'parameters',
        params: { search: data.value},
        cancelToken: new axios.CancelToken(c => cancel = c)
      }).then(res => {
            dispatch({
                type: 'FINISH_SEARCH',
                results: res.data.results
            })
        }).catch(e => {
            if (axios.isCancel(e)) return
        })
    }, 300)
  }, [])
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])
  return (
      
      <Search
        fluid
        placeholder={"Search for parameters"}
        loading={loading}
        onResultSelect={(e, data) =>
          dispatch({ type: 'UPDATE_SELECTION', selection: data.result })
        }
        onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer}
        results={results.slice(0, 7)}
        value={value}
      />
  )
}

export default SearchParameters
