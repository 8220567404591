import './Price.css'
function PriceInfo(){
    return(

        <div className="row banner-price__content">
            <div className="text-end">

                <p><strong>Annual information cost <span className="ps-4">€688.74</span></strong></p>

                <p><strong>Annual High Frequency Products information cost <span className="ps-4">€550.99</span></strong></p>

                <p><strong>Annual ECMWF handling charge <span className="ps-4">€247.95</span></strong></p>

                <p className="mt-5"><strong>Annual total cost <span className="ps-4">€1,487.68</span></strong></p>

            </div>
        </div>

    );
}

export default PriceInfo;