import { ARCHIVE } from "../../constDefinition";
import Keywords from '../search-form/search-form-components/Keywords';
import Parameters from '../search-form/search-form-components/Parameters';
import Datasets from '../search-form/search-form-components/Datasets';
import Period from '../search-form/search-form-components/Period';
import CheckboxFilters from '../search-form/search-form-components/CheckboxFilters';
import './ResultArchiveSidebar.css'

function ResultsArchiveSidebar() {

    return (
      <div>
        <h2>Refine your search</h2>
        <Keywords form={ARCHIVE} view="results"/>
        <Period view="results"/>
        {/*<Datasets view="results"/>*/}
        <Parameters form={ARCHIVE} view="results"/>
        <CheckboxFilters view="results" form={ARCHIVE}/>
      </div>
    );
  }
  
  export default ResultsArchiveSidebar;
  