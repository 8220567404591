import '../search-form.css';
import InputIcon from "../../item-components/input-icon";
import useStyles from '../../item-components/accordionSidebarStyle';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { selectKeywordARC, selectKeywordRT } from '../../../actions';
import { ARCHIVE } from '../../../constDefinition';

function Keywords(props) {
    const store = useSelector(state => props.form === ARCHIVE ? state.archiveForm.keyword : state.realTimeForm.keyword)
    const dispatch = useDispatch()
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
      };

    const handleChangeInput = (e) => {
        props.form === ARCHIVE ? dispatch(selectKeywordARC(e.target.value)) : dispatch(selectKeywordRT(e.target.value))
    }

    if(props.view === "search"){
        return (
            <div className='FormField'>
                <InputIcon onChange={handleChangeInput} placeholder={'Search for keyword, dataset or parameter'} value={store}/>
            </div>
        );
    }
    else{
        return (
            <div className={classes.root, "AccordionSidebar"}>
                <Accordion expanded={expanded } onChange={handleChange()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}><h3 className="Title">Keywords</h3></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width:"100%"}}>
                        <InputIcon onChange={handleChangeInput} placeholder={'Search for keyword, dataset or parameter'} value={store}/>
                    </div>
                </AccordionDetails>
                </Accordion>
        
            </div>
        );
    }
  }
  
  export default Keywords;
  