import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import tooltip from "../../icon/tooltip.png";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Button} from "semantic-ui-react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


export default function RightSidebar(props) {
    const classes = useStyles();
    //<Typography style={{marginRight: 20}}><img src={tooltip} alt='info' className="Tooltip Tooltip-right"></img></Typography>
    return (
        <Accordion expanded={props.expanded === props.panel } onChange={props.onChange(props.panel)}>
            <AccordionSummary
                expandIcon={props.expanded === props.panel ?<RemoveIcon />:<AddIcon/>}
                aria-controls={props.ariaControls}
                id={props.id}
            >
                <Typography className={classes.heading}><h3 className="Title">{props.title}</h3></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget
                    maximus est, id dignissim quam.
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}