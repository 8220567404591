import eye from '../../icon/eye.png'
import cart from '../../icon/cart.png'
import vline from '../../icon/vertical_line.png'
import './NavTools.css';
import {ARCHIVE, REALTIME} from '../../constDefinition';
import { useSelector, useDispatch } from 'react-redux';
import { realTimeActiveTab, archiveActiveTab } from '../../actions';
import { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

function NavTools(props) {
  const tabSelected = useSelector(state => state.activeTab)
  const dispatch = useDispatch();
  const basketItem = useSelector( state => state.orders.basket)
  useEffect(() => {
    //console.log(window.location.pathname)
  }, [])
  console.log(props.tab);
  if(props.tab){
    return (
      <div className='NavToolsBeetween'>
          <div className="NavToolsItemsLeft">
            <button className={(tabSelected === REALTIME ? 'SearchFormTabButtonEnabled' : 'SearchFormTabButtonDisabled')} onClick={() => dispatch(realTimeActiveTab())}>Real Time</button>
            <button className={(tabSelected === ARCHIVE ? 'SearchFormTabButtonEnabled' : 'SearchFormTabButtonDisabled')} onClick={() => dispatch(archiveActiveTab())}>Archive</button>
          </div>
          <div className="NavToolsItems">
          <Link to="/orders">
              <div className="order-nav-item">
                <img src={eye} alt='orders'></img>
                <h3>Orders</h3>
              </div> 
          </Link>
            <img src={vline} alt='vline'></img>
            <Link to="/basket">
              <div className="d-flex">
                <img className="basket-icon" src={cart} alt='cart'></img>
                <div className="itemsInBasket ms-3"><span className="m-auto">{basketItem.length}</span></div>
              </div>
            </Link>
        </div>
      </div>
    );
  }
  else{
    return (
      <div className='NavTools'>
          <div className="NavToolsItems">
            <Link to="/orders">
              <div className="order-nav-item">
                <img src={eye} alt='orders'></img>
                <h3>Orders</h3>
              </div> 
          </Link>
            <img src={vline} alt='vline'></img>
            <Link to="/basket">
              <div className="d-flex">
                <img className="basket-icon" src={cart} alt='cart'></img>
                <div className="itemsInBasket ms-3"><span className="m-auto">{basketItem.length}</span></div>
              </div>
            </Link>
        </div>
      </div>
    );
  }
  }
  
  export default NavTools;
  