import { Table } from 'semantic-ui-react';


function ParametersScrollerReadOnly(props){
    const items = props.items

    return (
        <div>
            <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Short Name</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(items).map(function(key, index){ 
                    var item = items[key]
                    return ( 
                        <Table.Row >
                            <Table.Cell>
                                {item.name}
                            </Table.Cell>
                            <Table.Cell>
                                {item.shortname}
                            </Table.Cell>
                            <Table.Cell>
                                {item.description}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
            </Table>
        </div>
    );
}


export default ParametersScrollerReadOnly;
