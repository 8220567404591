import './DetailsFooter.css'
import React from 'react';
import "flickity/dist/flickity.css";
import {useSelector} from "react-redux";
import Carousel from "../Carousel/Carousel";

const flickityOptions = {
    initialIndex: 1,
    groupCells: 2
}


function DetailsFooter() {
    let store = useSelector(state => state.datasetDetails.relatedDatasets)

    return (
        <section className="detail-footer">
            <h1 className="mb-4 text-center"><b>Related data</b></h1>
            <div className="carousel-container">
                <Carousel dataCarousel={store} flickityOptions={flickityOptions}/>
            </div>
        </section>

    )
}
export default DetailsFooter;