import '../search-form.css';
import PeriodSlider from "../../item-components/PeriodSlider";
import RadioButton from "../../item-components/RadioButton";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { PERIOD_INPUT, PERIOD_SLIDER } from '../../../constDefinition';
import { useDispatch, useSelector } from 'react-redux';
import { periodInput } from '../../../actions';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from '../../item-components/accordionSidebarStyle';
import { Divider } from 'semantic-ui-react'

function Period(props) {
    
  const dispatch = useDispatch()
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (event) => {
    dispatch(periodInput(event.target.value))
  }

  const handleChangeExpanded = () => (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  var valueInput = useSelector(state => state.archiveForm.periodInput)


  if(props.view === "search"){
    return (
        <div className="FormField">
            <div className="SearchFormBox ">
                <h2 className="Title">Period</h2>
                <div className="row">
                    <div className="col-md-3 Horizontal" style={{borderRight: "1px solid #9EA9B6"}}>
                        <RadioButton field={PERIOD_INPUT}/>
                        <TextField
                            id="outlined-number"
                            type="number"
                            variant="outlined"
                            style={{ padding: 0, width: 90}}
                            value={valueInput}
                            onChange={handleChange}
                            />
                    </div>
                    <div className="col-md-9 Horizontal">
                        <RadioButton field={PERIOD_SLIDER}/>
                        <PeriodSlider />
                    </div>
                </div>
            </div>
        </div>
      );
    }
    else{
      return (
        <div className={classes.root, "AccordionSidebar"}>
                <Accordion expanded={expanded} onChange={handleChangeExpanded()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}><h3 className="Title">Period</h3></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width:"100%"}}>
                        <div className="Horizontal">
                            <RadioButton field={PERIOD_INPUT}/>
                            <label>
                              Select a full year
                            </label>
                            <TextField
                                id="outlined-number"
                                type="number"
                                variant="outlined"
                                style={{ padding: 0, width: 90}}
                                value={valueInput}
                                onChange={handleChange}
                                />
                        </div>
                        <Divider />
                        <div className="Horizontal PeriodSliderDown">
                            <RadioButton field={PERIOD_SLIDER}/>
                            <label>
                              Select Start and End Date
                            </label>
                        </div>
                        <PeriodSlider />
                    </div>
                </AccordionDetails>
                </Accordion>
        
            </div>


        
      );
    }
  }
  
  export default Period;
  