import './BasketItem.css'
import ProductContent from "./ProductContent";
import {ProductItemHeader} from "./ProductItemHeader";

function BasketItem(props){
    var item = props.item
    var index = props.index
    return(
        <div className="basket-item my-5">
            <ProductItemHeader index={index} item={item} header={props.header} indexOrder={props.indexOrder}/>

            <div className="basket-item__body">
                <ProductContent item={item}/>
            </div>
        </div>
    );
}
export default BasketItem;