import React from "react";
import './LoginBanner.css'
import { Button } from 'semantic-ui-react'
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

function loginBanner(){
    return(
        <div className="login-banner">
            <Alert severity="error">
                <div className="mb-3">
                    <AlertTitle>Login</AlertTitle>
                    <p>Please login to see all the available options for your account</p>
                </div>
                <div className="actions">
                    <Button color='red'>Login</Button>
                    <Button basic color='red'>Sign-up</Button>
                </div>
            </Alert>

        </div>
    )
}

export default loginBanner;


