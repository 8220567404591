import './search-form.css';
import Keywords from './search-form-components/Keywords';
import Parameters from './search-form-components/Parameters';
import Datasets from './search-form-components/Datasets';
import Period from './search-form-components/Period';
import CheckboxFilters from './search-form-components/CheckboxFilters';
import { Button } from 'semantic-ui-react'
import {ARCHIVE} from "../../constDefinition"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { archiveReset, periodDefault } from '../../actions';

function ArchiveForm() {
    const dispatch = useDispatch()
    return (
        
        <div className="box SearchForm">
            <div className="FormField">
                <h2>Select the Archive version</h2>
            </div>
            <Keywords form={ARCHIVE} view="search"/>
            <Period view="search"/>
            {/*<Datasets view="search"/>*/}
            <Parameters view="search" form={ARCHIVE}/>
            <CheckboxFilters view="search" form={ARCHIVE}/>
            <div className="SeachForm-CTA">
                <Button secondary
                onClick={() => {
                    dispatch(archiveReset())
                  }}>Reset</Button>
                <Link to="/results">
                    <Button primary>
                        Search
                    </Button>
                </Link>
            </div>
      </div>
    );
  }
  
  export default ArchiveForm;
  