import { useDispatch, useSelector } from 'react-redux';
import {ARCHIVE, PERIOD_INPUT, LIMIT_PAGINATION, COLUMN, GRID} from "../../constDefinition"
import Card from './Card';
import './Results.css'
import { useEffect, useState } from 'react';
import list_visualisation from '../../icon/list_visualisation.png'
import grid_visualisation from '../../icon/grid_visualisation.png'
import list_visualisation_enabled from '../../icon/list_visualisation_enabled.png'
import grid_visualisation_enabled from '../../icon/grid_visualisation_enabled.png'


import {
    searchArchive,
    searchRealtime,
    setPageArchive,
    setPageRealtime,
    startLoadingArchive,
    startLoadingRealtime,
    updateVisulisations
} from '../../actions';
import { Dimmer, Loader, Pagination, Segment } from 'semantic-ui-react'
import React from 'react';
import { Icon } from 'semantic-ui-react'


function Results(props) {
    const dispatch = useDispatch()
    const store = useSelector(state => props.form === ARCHIVE ? state.archiveForm : state.realTimeForm)
    const storeActiveTab = useSelector(state => state.activeTab)
    const storeLoading = useSelector(state => props.form === ARCHIVE ? state.archiveForm.loading : state.realTimeForm.loading)
    const storeAccess = useSelector(state => props.form === ARCHIVE ? state.archiveForm.accessArchive : state.realTimeForm.accessRealtime)
    const storeProvisioning = useSelector(state => props.form === ARCHIVE ? state.archiveForm.provisioningArchive : state.realTimeForm.provisioningRealtime)
    const storeFormatData = useSelector(state => props.form === ARCHIVE ? state.archiveForm.formatDataArchive : state.realTimeForm.formatDataRealtime)
    const storeParameters = useSelector(state => props.form === ARCHIVE ? state.archiveForm.parameters: state.realTimeForm.parameters)
    const storeDatasets = useSelector(state => state.archiveForm.datasets)
    const storePeriod = useSelector(state => state.archiveForm.period)
    const storePeriodInput = useSelector(state => state.archiveForm.periodInput)
    const storePeriodSlider = useSelector(state => state.archiveForm.periodSlider)
    const storeReanalysis = useSelector(state => state.archiveForm.reanalysis)
    const storeMachineLearning = useSelector(state => state.archiveForm.machineLearningArchive)
    const storeActivePage = useSelector(state => props.form === ARCHIVE ? state.archiveForm.page : state.realTimeForm.page)
    const storeKeyword = useSelector(state => props.form === ARCHIVE ? state.archiveForm.keyword : state.realTimeForm.keyword)
    const visualisation = useSelector(state => state.resultVisualization.resultVisualization);

    const onChangePagination = (e, pageInfo) => {
        console.log(pageInfo.activePage)
        props.form === ARCHIVE ? dispatch(setPageArchive(pageInfo.activePage)) : dispatch(setPageRealtime(pageInfo.activePage))
    };

    function getQueryOptions(options){
        var activeOptions = ""
        Object.values(options).map(item=>{
            if(item.status){
                if(activeOptions != ""){
                    activeOptions = activeOptions+","+item.code
                }
                else{
                    activeOptions = item.code
                }
            }
        });
        return activeOptions
    }

    function getQueryOptionsParameters(options){
        var activeOptions = ""
        options.map(item=>{
            console.log(item)
            if(activeOptions != ""){
                activeOptions = activeOptions+","+item.id
            }
            else{
                activeOptions = ""+item.id
            }
            console.log(activeOptions)
        });
        return activeOptions
    }

    var scopeUseEffect = [storeActivePage,storeActiveTab, storeKeyword, storeAccess, storeProvisioning, storeFormatData, storeParameters]
    if(props.form == ARCHIVE){
        scopeUseEffect.push(storePeriod, storeDatasets, storePeriodInput, storePeriodSlider, storeReanalysis, storeMachineLearning)
    }

    useEffect(() => {
        console.log("SEARCH!!!!!!!")
        console.log(storeParameters)
        var q = {}
        q["limit"] = LIMIT_PAGINATION
        console.log(storeActivePage)
        q["offset"] = (storeActivePage-1)*LIMIT_PAGINATION
        q["nature"] = props.form === ARCHIVE ? "archive" : "realtime"

        if(store.keyword && store.keyword != ""){
            q["search"] = store.keyword
        }

        var  parameters = getQueryOptionsParameters(store.parameters)
        if(parameters.length > 0){
            q["parameters"] = parameters
        }

        var delivery = getQueryOptions(props.form === ARCHIVE ? store.provisioningArchive : store.provisioningRealtime)
        if(delivery.length > 0){
            q["delivery"] = delivery
        }

        var access = getQueryOptions(props.form === ARCHIVE ? store.accessArchive : store.accessRealtime)
        if(access.length > 0){
            q["access"] = access
        }

        var encoding = getQueryOptions(props.form === ARCHIVE ? store.formatDataArchive : store.formatDataRealtime)
        if(encoding.length > 0){
            q["encoding"] = encoding
        }

        if(props.form === ARCHIVE){
            if(store.period === PERIOD_INPUT){
                q["year"] = store.periodInput
            }
            else{
                q["start_year"] = store.periodSlider[0]
                q["end_year"] = store.periodSlider[1]
            }

            var reanalysis = getQueryOptions(store.reanalysis)
            if(reanalysis.length > 0){
                q["reanalysis"] = reanalysis
            }

            var ml = getQueryOptions(props.form === ARCHIVE ? store.machineLearningArchive : store.machineLearningRealtime)
            if(ml.length > 0){
                q["machine_learning"] = ml
            }
        }

        console.log(q)
        props.form === ARCHIVE ? dispatch(startLoadingArchive()) : dispatch(startLoadingRealtime())
        props.form === ARCHIVE ? dispatch(searchArchive(q)) : dispatch(searchRealtime(q))
    }, scopeUseEffect)


    return (

        <>

            <div className="d-flex justify-content-between">
                <div>
                    <h1><b>Search ECMWF datasets</b></h1>
                    <label>Results: {store.count}</label>
                </div>
                <div className="icon-visualisation">
              <span onClick={ ()=>{
                  dispatch(updateVisulisations(COLUMN))
              }}>{visualisation === COLUMN ? <img src={list_visualisation_enabled} alt='info' className=""></img> : <img src={list_visualisation} alt='info' className=""></img>}</span>
                    <span className="ms-3" onClick={()=>{
                        dispatch(updateVisulisations(GRID))
                    }}>{visualisation === GRID ? <img src={grid_visualisation_enabled} alt='info' className=""></img> : <img src={grid_visualisation} alt='info' className=""></img>}</span>

                </div>

            </div>
            <Segment>
                <div className="row ResultsGrid">

                    {storeLoading? <Dimmer blurring inverted active><Loader size='huge' inverted>Loading</Loader></Dimmer> :
                        store.results && store.results.length > 0 ? store.results.map(item => {
                            return (
                                <div className={ visualisation == GRID ? 'col-md-6' : 'col-md-12'}>
                                    <Card visualisation={visualisation} item={item} form={props.form}></Card>
                                </div>
                            )
                        }) : <div><h2>"oh oh, your search criteria seem too narrow and no results can be found. Please try broadening your criteria and search again"</h2></div>}

                </div>
                {store.results && store.results.length > 0 &&
                <div style={{textAlign: "center", marginTop: "60px"}}>
                    <Pagination
                        activePage={storeActivePage}
                        onPageChange={onChangePagination}
                        totalPages={Math.ceil(store.count/LIMIT_PAGINATION)}
                        ellipsisItem={null}
                    />
                </div>
                }
            </Segment>
        </>

    );
};

export default Results;
  