import '../search-form.css';
import Access from './Access';
import Provisioning from './Provisioning';
import FormatData from './FormatData';
import MachineLearning from './MachineLearning';
import Reanalysis from './Reanalysis';
import useStyles from '../../item-components/accordionSidebarStyle';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ARCHIVE } from '../../../constDefinition';

function CheckboxFilters(props) {

    const classes = useStyles();
    const [expanded1, setExpanded_1] = React.useState(true);
    const [expanded2, setExpanded_2] = React.useState(true);
    const [expanded3, setExpanded_3] = React.useState(true);
    const [expanded4, setExpanded_4] = React.useState(true);
    const [expanded5, setExpanded_5] = React.useState(true);

    const handleChange = (panel) => (event, isExpanded) => {
        switch(panel){
            case 'panel1':
                setExpanded_1(isExpanded ? true : false);
                return
            case 'panel2':
                setExpanded_2(isExpanded ? true : false);
                return
            case 'panel3':
                setExpanded_3(isExpanded ? true : false);
                return
            case 'panel4':
                setExpanded_4(isExpanded ? true : false);
                return
            case 'panel5':
                setExpanded_5(isExpanded ? true : false);
                return
        }
      };

    if(props.view === "search"){
        return (
            <>
            {props.form === ARCHIVE ?
                <>
                    <div className="row FormField">
                        <div className="col-12">
                            <Reanalysis view="search"/>
                        </div>
                    </div>
                    <div className="row FormField">
                        <div className="col-12">
                            <MachineLearning view="search" form={props.form}/>
                        </div>
                    </div>
                </>
            : <></>}
            <div className="row FormField">
                <div className="col-md-4 col-sm-6">
                    <Access view="search" form={props.form}/>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Provisioning view="search" form={props.form}/>
                </div>
                <div className="col-md-4 col-sm-6">
                    <FormatData view="search" form={props.form}/>
                </div>
            </div>
            </>
        );
    }
    else{
        return (
            <div>
                {props.form === ARCHIVE ?
                    <>
                        <div className={classes.root, "AccordionSidebar"}>
                            <Accordion expanded={expanded5} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <Typography className={classes.heading}><h3 className="Title">Reanalysis</h3></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <Reanalysis view="results" form={props.form}/>
                                </div>
                            </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className={classes.root, "AccordionSidebar"}>
                            <Accordion expanded={expanded4} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel4bh-header"
                            >
                                <Typography className={classes.heading}><h3 className="Title">Machine Learning purpose</h3></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <MachineLearning view="results" form={props.form}/>
                                </div>
                            </AccordionDetails>
                            </Accordion>
                        </div>
                    </>
                : <div></div> }
                <div className={classes.root, "AccordionSidebar"}>
                    <Accordion expanded={expanded1} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className={classes.heading}><h3 className="Title">Access</h3></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Access view="results" form={props.form}/>
                        </div>
                    </AccordionDetails>
                    </Accordion>
                </div>
                <div className={classes.root, "AccordionSidebar"}>
                    <Accordion expanded={expanded2} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel3bh-header"
                    >
                        <Typography className={classes.heading}><h3 className="Title">Provisioning</h3></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Provisioning view="results" form={props.form}/>
                        </div>
                    </AccordionDetails>
                    </Accordion>
                </div>
                <div className={classes.root, "AccordionSidebar"}>
                    <Accordion expanded={expanded3} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel3bh-header"
                    >
                        <Typography className={classes.heading}><h3 className="Title">Format of Data</h3></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <FormatData view="results" form={props.form}/>
                        </div>
                    </AccordionDetails>
                    </Accordion>
                </div>
                
            </div>
        );
    }
}
  
export default CheckboxFilters;
  