import { LOGGED, NOT_LOGGED } from "../constDefinition"

const initial_state = {
    logged: false,
}

const isLoggedReducer = (state = initial_state, action) => {
    switch(action.type) {
        case LOGGED:
            return {...state, logged:true}
        case NOT_LOGGED:
            return {...state, logged:false}
    }
    return state
}

export default isLoggedReducer;