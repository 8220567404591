import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseURL } from '../../services/constDefinitionService';

export default function ParametersManager(query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [items, setItems] = useState({})
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setItems([])
  }, [query])

  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel
    
    axios({
        method: 'GET',
        url: baseURL + 'parameters',
        params: { limit: 25, offset: pageNumber, search: query},
        cancelToken: new axios.CancelToken(c => cancel = c)
      }).then(res => {
        setItems(prevItems => {
            var results = res.data.results.reduce(function(map, obj) {
                map[obj.id] = obj;
                return map;
            }, {});
            return Object.assign({},items, results)
        })
        setHasMore(res.data.next != null)
        setLoading(false)
    }).catch(e => {
      if (axios.isCancel(e)) return
      setError(true)
    })
    return () => cancel()
  }, [query, pageNumber])

  return { loading, error, items, hasMore }
}