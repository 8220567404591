import { useSelector } from 'react-redux';
import '../item-components/Order.css';
import OrderItem from "../item-components/OrderItem";
function OrdersPage() {
    
    const orders = useSelector(state => state.orders.orders)

    return (
        <div className="Page">
            <h1 className="mb-5">Your Orders</h1>
            {orders.map((item, index) =>
                <div className="order mb-3">
                    <div className="order-header">
                        <div className="row">
                            <div className="col-md-1">Ref. no</div>
                            <div className="col-md-7">Name</div>
                            <div className="col-md-1">Status</div>
                            <div className="col-md-1">Last updated</div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                    <OrderItem item={item} index={index}/>
                </div>
            )}
        </div>
    );
  }
  
  export default OrdersPage;
  