
import { ARCHIVE, DOCUMENTATION, DOWNLOAD_DATA, OVERVIEW } from '../../constDefinition';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import DetailsHeader from "./DetailsHeader";
import Card from "./Card";

function Documentation(props) {
    var store = useSelector(state => state.datasetDetails)
    return (
        <Card>
            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla imperdiet in leo sit amet hendrerit. Integer non est ultrices, pulvinar dolor at, luctus odio. Quisque eget lobortis ligula, et feugiat dui. Etiam pulvinar, nunc ut vestibulum molestie, magna purus interdum massa, eget viverra mi tellus id libero. Vivamus et nibh eu turpis dignissim ultrices in in lectus. Morbi vehicula ac augue vel mollis. Pellentesque vel vulputate elit.

Phasellus ut malesuada nisl, sit amet pellentesque purus. Etiam id ligula posuere, pellentesque lorem sit amet, accumsan nisi. Proin lorem eros, congue in nulla sit amet, vulputate rutrum velit. Sed gravida sem a diam sagittis dapibus porta vel neque. Sed porta, nisi ut tempus mollis, ante augue auctor justo, et pellentesque diam felis vitae diam. Nam accumsan tempor purus, in dignissim lectus porta in. Donec vel dui eleifend, feugiat mi non, ultrices ipsum. Curabitur ut porttitor diam.

Nulla venenatis sit amet ipsum nec fringilla. Aenean non vestibulum leo, ac auctor nisi. Etiam eu cursus ipsum, nec luctus ligula. Quisque id purus tempus, rhoncus ligula quis, sagittis eros. Nulla dictum enim et augue accumsan, et consectetur risus elementum. Etiam vitae leo nisl. Integer iaculis felis ac lorem posuere volutpat. Ut at diam quam. Ut odio metus, fermentum vel auctor a, ullamcorper vitae libero. Nam tincidunt nisl quis dignissim volutpat. Donec eget fringilla enim, ut porta quam. Suspendisse tincidunt tempor sem, rhoncus sagittis velit porta condimentum. Vestibulum mauris risus, porttitor sed egestas ut, egestas vitae justo. Maecenas molestie lorem eu fringilla feugiat. Morbi accumsan et diam ultricies interdum. </p>
        </Card>
    )

};

export default Documentation;