
import { 
    ACCESS_ARCHIVE, 
    PERIOD_INPUT_SELECT,
    PERIOD_SLIDER_SELECT,
    PERIOD_SLIDER_SELECT_RADIO,
    PERIOD_INPUT_SELECT_RADIO,
    PERIOD_INPUT,
    PERIOD_SLIDER,
    ACCESS_CHECKBOXES_ARCHIVE,
    PROVISIONING_CHECKBOXES_ARCHIVE,
    PROVISIONING_ARCHIVE,
    FORMAT_DATA_ARCHIVE,
    FORMAT_DATA_CHECKBOXES_ARCHIVE,
    ML_ARCHIVE,
    ML_CHECKBOXES_ARCHIVE,
    PARAMETERS_SELECT_ARCHIVE,
    PARAMETERS_REMOVE_ARCHIVE,
    DATASETS_SELECT_ARCHIVE,
    DATASETS_REMOVE_ARCHIVE,
    PARAMETERS_REMOVE_ALL_ARCHIVE,
    DATASETS_REMOVE_ALL_ARCHIVE,
    REANALYSIS_CHECKBOXES_ARCHIVE,
    REANALYSIS_ARCHIVE,
    PARAMETERS_ADD_ARCHIVE,
    DATASETS_ADD_ARCHIVE,
    SEARCH_ARCHIVE,
    ARCHIVE_KEYWORD_SELECT,
    PERIOD_INPUT_DEFAULT,
    PERIOD_SLIDER_DEFAULT,
    RESET_ARCHIVE,
    START_LOADING_ARCHIVE,
    LIMIT_PAGINATION,
    CHANGE_PAGE_ARCHIVE
} from "../constDefinition"

const initialState = {
    keyword: "",
    period: PERIOD_INPUT,
    periodInput: 0,
    defaultPeriodInput: 0,
    periodSlider: [],
    defaultPeriodSlider: [],
    parameters: [],
    datasets: [],
    accessArchive: {},
    provisioningArchive: {},
    formatDataArchive: {},
    machineLearningArchive: {},
    reanalysis: {},
    results: [],
    count: 0,
    page: 1,
    loading: false
}

function resetCheckboxes(checkboxes){
    for (const [key, value] of Object.entries(checkboxes)) {
        value.status = false
      }
    var editedCheckboxes = {}
    editedCheckboxes = Object.assign(editedCheckboxes, checkboxes);
    return editedCheckboxes
      
}


const archiveFormReducer = (state = initialState, action) => {
    
    switch(action.type) {
        case START_LOADING_ARCHIVE:
            return {...state, loading: true}
        case RESET_ARCHIVE:
            return { 
                ...state, 
                keyword: '', 
                parameters: [], 
                datasets: [],
                accessArchive: resetCheckboxes(state.accessArchive),
                provisioningArchive: resetCheckboxes(state.provisioningArchive),
                formatDataArchive: resetCheckboxes(state.formatDataArchive),
                machineLearningArchive: resetCheckboxes(state.machineLearningArchive),
                reanalysis: resetCheckboxes(state.reanalysis),
                periodInput: state.defaultPeriodInput,
                periodSlider: state.defaultPeriodSlider
            }
        case ARCHIVE_KEYWORD_SELECT:
            state.keyword = action.value
            return state
        case ACCESS_CHECKBOXES_ARCHIVE:
            if(action.value){
                var accessArchive = {}
                state.accessArchive = {}
                accessArchive = Object.assign(accessArchive, state.accessArchive);
                action.value.forEach(checkbox => {
                    var accessCheckbox = {}
                    accessCheckbox = Object.assign(accessCheckbox, checkbox)
                    accessCheckbox["status"] = false
                    state.accessArchive[accessCheckbox.code] = accessCheckbox
                });
            }
            return state;

        case ACCESS_ARCHIVE:
            if(state.accessArchive[action.code]){
                var accessArchive = {}
                accessArchive = Object.assign(accessArchive, state.accessArchive);
                accessArchive[action.code]["status"] = !accessArchive[action.code]["status"]
                state.accessArchive = accessArchive
            }
            return state

        case PROVISIONING_CHECKBOXES_ARCHIVE:
            if(action.value){
                var provisioningArchive = {}
                state.provisioningArchive = {}
                provisioningArchive = Object.assign(provisioningArchive, state.provisioningArchive);
                action.value.forEach(checkbox => {
                    var provisioningCheckbox = {}
                    provisioningCheckbox = Object.assign(provisioningCheckbox, checkbox)
                    provisioningCheckbox["status"] = false
                    provisioningArchive[provisioningCheckbox.code] = provisioningCheckbox
                });
                state.provisioningArchive = provisioningArchive
            }
            return state;

        case PROVISIONING_ARCHIVE:
            if(state.provisioningArchive[action.code]){
                var provisioningArchive = {}
                provisioningArchive = Object.assign(provisioningArchive, state.provisioningArchive);
                provisioningArchive[action.code]["status"] = !provisioningArchive[action.code]["status"]
                state.provisioningArchive = provisioningArchive;
            }
            return state
        
        case FORMAT_DATA_CHECKBOXES_ARCHIVE:
            if(action.value){
                var formatDataArchive = {}
                state.formatDataArchive = {}
                formatDataArchive = Object.assign(formatDataArchive, state.formatDataArchive);
                action.value.forEach(checkbox => {
                    var formatDataArchiveCheckbox = {}
                    formatDataArchiveCheckbox = Object.assign(formatDataArchiveCheckbox, checkbox)
                    formatDataArchiveCheckbox["status"] = false
                    formatDataArchive[formatDataArchiveCheckbox.code] = formatDataArchiveCheckbox
                });
                state.formatDataArchive = formatDataArchive
            }
            return state;

        case FORMAT_DATA_ARCHIVE:
            if(state.formatDataArchive[action.code]){
                var formatDataArchive = {}
                formatDataArchive = Object.assign(formatDataArchive, state.formatDataArchive);
                formatDataArchive[action.code]["status"] = !formatDataArchive[action.code]["status"]
                state.formatDataArchive = formatDataArchive
            }
            return state

        case ML_CHECKBOXES_ARCHIVE:
            if(action.value){
                var machineLearningArchive = {}
                state.machineLearningArchive = {}
                machineLearningArchive = Object.assign(machineLearningArchive, state.machineLearningArchive);
                action.value.forEach(checkbox => {
                    var machinelearningArchiveCheckbox = {}
                    machinelearningArchiveCheckbox = Object.assign(machinelearningArchiveCheckbox, checkbox)
                    machinelearningArchiveCheckbox["status"] = false
                    machineLearningArchive[machinelearningArchiveCheckbox.code] = machinelearningArchiveCheckbox
                });
                state.machineLearningArchive = machineLearningArchive
            }
            return state;

        case ML_ARCHIVE:
            if(state.machineLearningArchive[action.code]){
                var machineLearningArchive = {}
                machineLearningArchive = Object.assign(machineLearningArchive, state.machineLearningArchive);
                machineLearningArchive[action.code]["status"] = !machineLearningArchive[action.code]["status"]
                state.machineLearningArchive = machineLearningArchive
            }
            return state
        
        case REANALYSIS_CHECKBOXES_ARCHIVE:
            if(action.value){
                var reanalysis = {}
                state.reanalysis = {}
                reanalysis = Object.assign(reanalysis, state.reanalysis);
                action.value.forEach(checkbox => {
                    var reanalysisCheckbox = {}
                    reanalysisCheckbox = Object.assign(reanalysisCheckbox, checkbox)
                    reanalysisCheckbox["status"] = false
                    reanalysis[reanalysisCheckbox.code] = reanalysisCheckbox
                });
                state.reanalysis = reanalysis
            }
            return state;

        case REANALYSIS_ARCHIVE:
            if(state.reanalysis[action.code]){
                var reanalysis = {}
                reanalysis = Object.assign(reanalysis, state.reanalysis);
                reanalysis[action.code]["status"] = !reanalysis[action.code]["status"]
                state.reanalysis = reanalysis
            }
            return state
        
        case PARAMETERS_ADD_ARCHIVE:
            if(state.parameters.indexOf(action.item) == -1){
                return {
                    ...state,
                    parameters: [...state.parameters, action.item]
                }
            }
            return state
        
        case PARAMETERS_SELECT_ARCHIVE:
            var items = action.items
            var checkboxSelected = action.options
            var parameters = Object.keys(checkboxSelected).map(function(key){
                if(checkboxSelected[key]){
                    return Object.assign({}, items[key])
                }
                })
            parameters = parameters.filter(function (obj) {
                return obj !== undefined
            })
            state.parameters = []
            state.parameters = Object.assign(state.parameters, parameters)
            return state
        
        case PARAMETERS_REMOVE_ARCHIVE:
            var item = action.item
            var parameters = state.parameters.filter(function( obj ) {
                return obj.id !== item.id;
              });
              state.parameters = []
              state.parameters = Object.assign(state.parameters, parameters)
              return state
    
        case PARAMETERS_REMOVE_ALL_ARCHIVE:
            state.parameters = []
            return state

        case DATASETS_ADD_ARCHIVE:
            if(state.datasets.indexOf(action.item) == -1){
                return {
                    ...state,
                    datasets: [...state.datasets, action.item]
                }
            }
            return state
              
        case DATASETS_SELECT_ARCHIVE:
            var items = action.items
            var checkboxSelected = action.options
            var datasets = Object.keys(checkboxSelected).map(function(key){
                if(checkboxSelected[key]){
                    return Object.assign({}, items[key])
                }
                })
                datasets = datasets.filter(function (obj) {
                return obj !== undefined
            })
            state.datasets = []
            state.datasets = Object.assign(state.datasets, datasets)
            return state
    
        case DATASETS_REMOVE_ARCHIVE:
            var item = action.item
            var datasets = state.datasets.filter(function( obj ) {
                return obj.id !== item.id;
            });
            state.datasets = []
            state.datasets = Object.assign(state.datasets, datasets)
            return state

        case DATASETS_REMOVE_ALL_ARCHIVE:
            state.datasets = []
            return state

        case PERIOD_INPUT_DEFAULT:
            state.defaultPeriodInput = action.value;
            state.periodInput = action.value;
            return state;
        case PERIOD_SLIDER_DEFAULT:
            state.defaultPeriodSlider = [action.value["start_year"], action.value["end_year"]];
            state.periodSlider = [action.value["start_year"], action.value["end_year"]];
            return state;

        case PERIOD_INPUT_SELECT_RADIO:
            state.period = PERIOD_INPUT;
            return state;
        case PERIOD_SLIDER_SELECT_RADIO:
            state.period = PERIOD_SLIDER;
            return state;
        
        case PERIOD_SLIDER_SELECT:
            state.period = PERIOD_SLIDER;
            state.periodSlider = action.value;
            return state;
        case PERIOD_INPUT_SELECT:
            state.period = PERIOD_INPUT;
            state.periodInput = action.value;
            return state;

        case SEARCH_ARCHIVE:
            state.results = []
            state.results = Object.assign([], action.results.results)
            state.count = action.results.count
            state.loading = false
            return state

        case CHANGE_PAGE_ARCHIVE:
            return {...state, page: action.value}
        default:
            return state;
    }
}

export default archiveFormReducer;