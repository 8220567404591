import { useDispatch } from 'react-redux'
import { Icon, Label, Popup } from 'semantic-ui-react'
import { removeDatasetsArchive, removeDatasetsRealTime, removeParameterArchive, removeParameterRealTime } from '../../actions'
import { ARCHIVE, PARAMETERS } from '../../constDefinition'



function TagLabel(props){

    return(
        <Popup content={props.popup} trigger={
            <Label image className="TagLabel">
            {props.title}
            {<Icon circular inverted name='delete' 
                    onClick={() => props.removeCallback(props.item)}/>}
                
            </Label>
        } />
        
    )
}

export default TagLabel