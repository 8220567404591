
import TopBar from './components/top-bar/TopBar';
import BasketPage from './components/main-components/BasketPage';
import CheckoutPage from './components/main-components/CheckoutPage';
import DetailsPage from './components/main-components/DetailsPage';
import OrdersPage from './components/main-components/OrdersPage';
import ResultsPage from './components/main-components/ResultsPage';
import SearchFormPage from './components/main-components/SearchFormPage';
import OrderDetails from "./components/main-components/OrderDetails";
import ChatBot from 'react-simple-chatbot';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { getMachineLearningAlternatives } from './services/MachineLearningService';
import { useDispatch, useSelector } from 'react-redux';
import {
    logged,
    notLogged,
    periodDefault,
    setAccessArchiveCheckboxes,
    setAccessRealTimeCheckboxes,
    setFormatDataArchiveCheckboxes,
    setFormatDataRealTimeCheckboxes,
    setMachineLearningArchiveCheckboxes,
    setProvisioningArchiveCheckboxes,
    setProvisioningRealTimeCheckboxes,
    setReanalysisArchiveCheckboxes
} from './actions';
import { getFormatDataAlternatives } from './services/FormatDataService';
import { useEffect } from 'react';
import { getProvisioningAlternatives } from './services/ProvisioningService';
import { getAccessAlternatives } from './services/AccessService';
import { getReanalysisAlternatives } from './services/ReanalysisService';
import Cookies from 'js-cookie'
import { ThemeProvider } from 'styled-components';
import ecmwfLogo from './icon/ecmwf.svg'
import { checkLogin } from './services/LoginCheckService';


function App() {

  const dispatch = useDispatch()
  
  checkLogin()
    .then(
      response => {
        if(response.data.user){
          dispatch(logged())
        }
        else{
          dispatch(notLogged())
        }
      }
    )

  useEffect(() => {
    dispatch(periodDefault())

    getProvisioningAlternatives()
    .then(response => {
        dispatch(setProvisioningArchiveCheckboxes(response.data)) 
        dispatch(setProvisioningRealTimeCheckboxes(response.data))
    })

    getAccessAlternatives()
    .then(response => {
        dispatch(setAccessArchiveCheckboxes(response.data)) 
        dispatch(setAccessRealTimeCheckboxes(response.data))
    })
    
    getFormatDataAlternatives()
    .then(response => {
        dispatch(setFormatDataArchiveCheckboxes(response.data))
        dispatch(setFormatDataRealTimeCheckboxes(response.data))
    })

    getMachineLearningAlternatives()
    .then(response => {
        dispatch(setMachineLearningArchiveCheckboxes(response.data)) 
    })

    getReanalysisAlternatives()
    .then(response => {
        dispatch(setReanalysisArchiveCheckboxes(response.data))
    })

}, [])

    const chatbotTheme = {
        background: '#F8F8F8',
        fontFamily: 'Helvetica Neue',
        headerBgColor: '#455FB3',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#455FB3',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
    };

  return (
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/results">
              <TopBar tab={true}/>
            </Route>
            <Route path="/">
              <TopBar tab={false}/>
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/basket" component={BasketPage} />
            <Route exact path="/checkout" component={CheckoutPage} />
            <Route exact path="/details" component={DetailsPage} />
            <Route exact path="/orders" component={OrdersPage} />
            <Route exact path="/order-details" component={OrderDetails} />
            <Route exact path="/results" component={ResultsPage} />
            <Route exact path="/" component={SearchFormPage} />
          </Switch>
            <div className="me-auto">
                <ThemeProvider theme={chatbotTheme}>
                  <ChatBot
                      botAvatar={ecmwfLogo}
                      headerTitle={'ECMWF Chatbot'}
                      floating={true}
                      opened={false}
                      recognitionEnable={true}
                      steps={[
                          {
                              id: '1',
                              message: 'Welcome, can I help you?',
                              trigger: '2',
                          },
                          {
                              id: '2',
                              user: true,
                              trigger: '2',
                          }
                      ]}
                  />
                </ThemeProvider>
            </div>
        </div>
      </Router>
  );
}

export default App;
