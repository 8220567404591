import '../search-form.css';
import useStyles from '../../item-components/accordionSidebarStyle';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import TagLabel from '../../item-components/TagLabel';
import { useDispatch, useSelector } from 'react-redux';
import DatasetsModal from '../../modal/DatasetsModal'
import reset_cta from '../../../icon/reset_cta.png'
import SearchDatasets from '../../item-components/SearchDatasets';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { removeAllDatasetsArchive, removeDatasetsArchive  } from '../../../actions';

function Datasets(props) {
    const dispatch = useDispatch()
    const store = useSelector(state => state.archiveForm.datasets)
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = () => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
      };

    function removeDatasetsFromStore(){
        dispatch(removeAllDatasetsArchive())
    }

    function removeCallback(item){
        dispatch(removeDatasetsArchive(item))
        
    }

    if(props.view === "search"){
        return (
            <div className="FormField">
                <div className="SearchFormBox ">
                    <SearchDatasets></SearchDatasets>
                    <div className="tag-container">
                        {store.map(function(item){
                            return (
                                <TagLabel popup={item.title} removeCallback={removeCallback} title={item.title} key={item.id} item={item}></TagLabel>
                                )
                        })}
                    </div>

                    <div className="row DatasetsAction">
                        <div className="col-md-6 col-sm-12">
                            Maecenas sed diam eget risus varius blandit sit amet non magna.
                        </div>
                        <div className="col-md-4 col-sm-6 DatasetsActionItems Pointer">
                            <DatasetsModal form={props.form}></DatasetsModal>
                        </div>
                        <div className="col-md-2 col-sm-6 DatasetsActionItems Pointer" onClick={removeDatasetsFromStore}>
                            <img src={reset_cta} alt='remove parameters'></img>
                            <label className="Red">Reset</label>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
    else{
        return (
            <div className={classes.root, "AccordionSidebar"}>
                <Accordion expanded={expanded} onChange={handleChange()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}><h3 className="Title">Dataset</h3></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width:"100%"}}>
                        <SearchDatasets form={props.form}></SearchDatasets>
                        <div className="tag-container">
                            {store.map(function(item){
                                return (
                                    <TagLabel popup={item.title} removeCallback={removeCallback} title={item.title} key={item.id} item={item}></TagLabel>
                                    )
                            })}
                        </div>
                        <div className="row DatasetsAction">
                            <div className="col-8 Pointer">
                                <DatasetsModal view={props.view} form={props.form}></DatasetsModal>
                            </div>
                            <div className="col-4 Pointer" onClick={removeDatasetsFromStore}>
                                <div className="IconLabel">
                                    <img src={reset_cta} alt='remove parameters'></img>
                                    <label className="Red">Reset</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
                </Accordion>
        
            </div>
        );
    }
  }
  
  export default Datasets;
  