import { useDispatch, useSelector } from 'react-redux';
import { setActiveTabDetails } from '../../actions';
import { DOCUMENTATION, DOWNLOAD_DATA, OVERVIEW } from '../../constDefinition';
import './DetailsHeader.css'

function DetailsHeader(props) {
    var dispatch = useDispatch()
    var store = useSelector(state => state.datasetDetails.datasetDetailsActiveTab)
    return (
        <div className="details-header">
            <h1 className="mb-5">{props.title}</h1>
            <div>
                <ul>
                    <li className={(store === OVERVIEW ? 'active' : '')} onClick={() => dispatch(setActiveTabDetails(OVERVIEW))}>Overview</li>
                    <li className={(store === DOWNLOAD_DATA ? 'active' : '')} onClick={() => dispatch(setActiveTabDetails(DOWNLOAD_DATA))}>Download Data</li>
                    <li className={(store === DOCUMENTATION ? 'active' : '')} onClick={() => dispatch(setActiveTabDetails(DOCUMENTATION))}>Documentation</li>
                </ul>
            </div>
        </div>
    )

};

export default DetailsHeader;