import React from 'react'
import './Modal.css'
import { Button, Image, Modal, Message } from 'semantic-ui-react'
import {Link} from "react-router-dom";
import { submitOrder } from '../../actions';
import { useDispatch } from 'react-redux';

function RequestSuccessfully(props) {
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState(false)
    const dispatch = useDispatch();

    let code =
        "import cdsapi\n" +
        "\n" +
        "c = cdsapi.Client()\n" +
        "\n" +
        "c.retrieve(\n" +
        "'derived-utci-historical',\n" +
        "{\n" +
        "    'format': 'zip',\n" +
        "    'variable': 'mean_radiant_temperature',\n" +
        "    'product_type': 'intermediate_dataset',\n" +
        "    'year': '2020',\n" +
        "    'month': '07',\n" +
        "    'day': '15',\n" +
        "    'anon_user_timestamp': '2021-05-25 15:49:29',\n" +
        "},\n" +
        "'download.zip')"

    return (
        <Modal
            onClose={() => {setOpen(false); setMessage(false)}}
            onOpen={() => {
                var filledForm = {}
                filledForm = Object.assign(filledForm, props.form)
                filledForm["provisioning"] = props.item.delivery[props.provisioning]
                filledForm["encoding"] = props.item.encoding[props.encoding]
                if(props.requestName === ''){
                    props.fieldValidation()
                    return
                }
                setOpen(true)
                dispatch(submitOrder(props.requestName, [{'requestName': props.requestName, 'dataset': props.item, 'selection': filledForm, 'version': props.version}], "Completed"))
            }}
            open={open}
            trigger={<Button className={props.isLogged ? "primary" : "disabled"}>{props.title}</Button>}
            className={'p-5'}
        >
            <Modal.Header className={'border-none px-0'}><h1>Request completed successfully</h1></Modal.Header>
            <Modal.Content className={'px-0'}>
                {message && <Message positive>
                    
                        <p>
                            Your script has been copied in the clipboard.
                        </p>
                    </Message>
                }
                <Modal.Description>
                    <p><strong>Your current order doesn’t require any payment. <br/> For your convenience, you can download the code snippet for your request here:</strong></p>
                </Modal.Description>

                <Modal.Description>
                    <pre className='code-request'>
                        {code}
                    </pre>
                </Modal.Description>
            </Modal.Content>
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <Modal.Actions className={'me-2'}>
                        <Button primary className={'m-0'}>Send Email</Button>
                    </Modal.Actions>
                    <Modal.Actions>
                        <Button primary className={'m-0'} onClick={() => setMessage(true)}>Copy</Button>
                    </Modal.Actions>
                </div>
                <div className="">
                    <Modal.Actions>
                        <Link to="/orders">
                            <Button basic color='blue' content='Blue' className={'m-0 modal-order-details'} onClick={() => setOpen(false)}>Go to your order details</Button>
                        </Link>
                    </Modal.Actions>
                </div>
            </div>

        </Modal>
    )
}

export default RequestSuccessfully;