import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'

function ViewOrder(props) {
    console.log(props.orderType);
    const [open, setOpen] = React.useState(false)
    let code =
        "import cdsapi\n" +
        "\n" +
        "c = cdsapi.Client()\n" +
        "\n" +
        "c.retrieve(\n" +
        "'derived-utci-historical',\n" +
        "{\n" +
        "    'format': 'zip',\n" +
        "    'variable': 'mean_radiant_temperature',\n" +
        "    'product_type': 'intermediate_dataset',\n" +
        "    'year': '2020',\n" +
        "    'month': '07',\n" +
        "    'day': '15',\n" +
        "    'anon_user_timestamp': '2021-05-25 15:49:29',\n" +
        "},\n" +
        "'download.zip')"

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button>View your script</Button>}
            actions={['Snooze', { key: 'done', content: 'Done', positive: true }]}
        >
            <Modal.Header className={'border-none px-0'}><h1 className="ps-4">{props.orderName}</h1></Modal.Header>
            <Modal.Description>
                {props.orderType ?
                    <pre className='code-request mb-0'>
                        {code}
                    </pre>
                : ''}
            </Modal.Description>
            <Modal.Actions>
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <Modal.Actions className={'me-2'}>
                            <Button primary className={'m-0'} onClick={() => setOpen(false)}>Send Email</Button>
                        </Modal.Actions>
                        <Modal.Actions>
                            <Button primary className={'m-0'} onClick={() => setOpen(false)}>Copy</Button>
                        </Modal.Actions>
                    </div>
                    <Button onClick={() => setOpen(false)} basic color='blue' content='Blue' className={'m-0 modal-order-details'}>Close</Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default ViewOrder;
