import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { periodInputRadio, periodSliderRadio } from '../../actions';
import { PERIOD_INPUT, PERIOD_SLIDER } from '../../constDefinition';

const CustomRadio = withStyles({
  root: {
    color: '#3855B3',
    '&$checked': {
      color: '#3855B3',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function RadioButton(props) {

  const dispatch = useDispatch()
  var dispatchFunction
  
  if(props.field === PERIOD_INPUT){
    dispatchFunction = periodInputRadio
  }
  else if(props.field === PERIOD_SLIDER){    
    dispatchFunction = periodSliderRadio
  }

  var periodSelector = useSelector(state => state.archiveForm.period)
  var stateSelector

  if(props.field === PERIOD_INPUT){
    stateSelector = periodSelector === PERIOD_INPUT
  }
  else if(props.field === PERIOD_SLIDER){
    stateSelector = periodSelector === PERIOD_SLIDER
  }

  return (
    <div>
      <CustomRadio
        checked={stateSelector}
        onChange={() => dispatch(dispatchFunction())}
        value="a"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />
    </div>
  );
}

export default RadioButton