

import React from 'react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import {Link} from "react-router-dom";
import {addToBasket} from "../../actions";
import {useDispatch} from "react-redux";

function RequestToBasket(props) {
    const [open, setOpen] = React.useState(false)
    const dispatch = useDispatch();
    
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                var filledForm = {}
                filledForm = Object.assign(filledForm, props.form)
                filledForm["provisioning"] = props.item.delivery[props.provisioning]
                filledForm["encoding"] = props.item.encoding[props.encoding]
                if(props.requestName === ''){
                    props.fieldValidation()
                    return
                }
                setOpen(true);
                dispatch(addToBasket(props.requestName, props.item, filledForm, props.version))
            }}
            open={props.requestName === '' ? '' : open}
            trigger={<Button className={props.isLogged ? "primary" : "disabled"}>{props.title}</Button>}
            className={'p-5'}
        >
            <Modal.Header className={'border-none px-0'}><h1>Request added to basket</h1></Modal.Header>
            <Modal.Content className={'px-0 mb-3'}>
                <Modal.Description>
                    <p><strong>Go to the basket to complete your order</strong></p>
                </Modal.Description>
            </Modal.Content>
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <Modal.Actions className={'me-2'}>
                        <Link to="/results">
                            <Button basic color='blue' content='Blue' className={'m-0'} onClick={() => setOpen(false)}>Go back to the Datasets catalogue</Button>
                        </Link>
                    </Modal.Actions>

                </div>
                <div >
                    <Modal.Actions>
                        <Link to="/basket">
                            <Button primary className={'m-0'} onClick={() => setOpen(false)}>Go to basket</Button>
                        </Link>
                    </Modal.Actions>
                </div>
            </div>
        </Modal>
    )
}

export default RequestToBasket