import ProductContent from "./ProductContent";
import {ProductItemHeader} from "./ProductItemHeader";

function CheckoutItem(props){
    return(
        <div className="basket-item my-5">
            <div className="row">
                <div className="col-md-9 basket-item__main">
                    <ProductItemHeader index={props.index} item={props.item} header={'checkout'} />
                    <div className="basket-item__body">
                        <ProductContent item={props.item}/>
                    </div>
                </div>
                <div className="col-md-3 checkout-area">
                    <div className="checkout-area__header text-center">
                        <h2>COST</h2>
                    </div>
                    <div className="row">
                        <div className="text-end">
                            <p>Volume daily <strong className="ps-5">3.35 MB</strong></p>

                            <p>Fields daily <strong className="ps-5">2,799.09</strong></p>

                            <p>Total EPUs* <strong className="ps-5">€688.74</strong></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default CheckoutItem