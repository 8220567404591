import { useDispatch, useSelector } from 'react-redux';
import { REALTIME } from '../../constDefinition';
import ResultsArchiveSidebar from '../results/ResultsArchiveSidebar';
import ResultsRealTimeSidebar from '../results/ResultsRealTimeSidebar';
import Results from '../results/Results';

function ResultsPage() {

  const tabSelected = useSelector(state => state.activeTab)

    return (
      <div className="Page">
        <div className="row">
            <div className="col-md-3" style={{paddingRight: 25}}>
            {tabSelected === REALTIME ? (  
              <ResultsRealTimeSidebar></ResultsRealTimeSidebar>
              ):
              (
                <ResultsArchiveSidebar></ResultsArchiveSidebar>
                )
              }
            </div>
            <div className="col-md-9" style={{paddingLeft: 25}}>
                <Results form={tabSelected}></Results>
            </div>
        </div>
      </div>
    );
  }
  
  export default ResultsPage;
  