//TAB
export const REALTIME = 'realtime';
export const ARCHIVE = 'archive';

// PAGINATION 
export const LIMIT_PAGINATION = 8;
export const CHANGE_PAGE_ARCHIVE = "CHANGE_PAGE_ARCHIVE";
export const CHANGE_PAGE_REALTIME = "CHANGE_PAGE_REALTIME";
        

// LOADING
export const START_LOADING_REALTIME = 'START_LOADING_REALTIME';
export const START_LOADING_ARCHIVE = 'START_LOADING_ARCHIVE';


// RESET FORM
export const RESET_REALTIME = 'RESET_REALTIME';
export const RESET_ARCHIVE = 'RESET_ARCHIVE';

// PARAMETER OR DATASETS
export const PARAMETERS = 'PARAMETERS';
export const DATASETS = 'DATASETS';


export const PERIOD_INPUT = 'PERIOD_INPUT';
export const PERIOD_SLIDER = 'PERIOD_SLIDER';

//ACTION
export const CHECK = 'CHECK';
export const UNCHECK = 'UNCHECK';

export const REALTIME_TAB_SELECT = 'REALTIME_TAB_SELECT';
export const ARCHIVE_TAB_SELECT = 'ARCHIVE_TAB_SELECT';

export const REALTIME_KEYWORD_SELECT = 'REALTIME_KEYWORD_SELECT';
export const ARCHIVE_KEYWORD_SELECT = 'ARCHIVE_KEYWORD_SELECT';

export const PERIOD_SLIDER_DEFAULT = 'PERIOD_SLIDER_DEFAULT';
export const PERIOD_INPUT_DEFAULT = 'PERIOD_INPUT_DEFAULT';


export const PERIOD_SLIDER_SELECT = 'PERIOD_SLIDER_SELECT';
export const PERIOD_INPUT_SELECT = 'PERIOD_INPUT_SELECT';

export const PERIOD_SLIDER_SELECT_RADIO = 'PERIOD_SLIDER_SELECT_RADIO';
export const PERIOD_INPUT_SELECT_RADIO = 'PERIOD_INPUT_SELECT_RADIO';


export const ACCESS_CHECKBOXES_ARCHIVE = "ACCESS_CHECKBOXES_ARCHIVE";
export const ACCESS_CHECKBOXES_REALTIME = "ACCESS_CHECKBOXES_REALTIME";

export const ACCESS_ARCHIVE = 'ACCESS_ARCHIVE';
export const ACCESS_REALTIME = 'ACCESS_REALTIME';

export const PROVISIONING_CHECKBOXES_ARCHIVE = "PROVISIONING_CHECKBOXES_ARCHIVE";
export const PROVISIONING_CHECKBOXES_REALTIME = "PROVISIONING_CHECKBOXES_REALTIME";

export const PROVISIONING_ARCHIVE = 'PROVISIONING_ARCHIVE';
export const PROVISIONING_REALTIME = 'PROVISIONING_REALTIME';

export const FORMAT_DATA_CHECKBOXES_ARCHIVE = "FORMAT_DATA_CHECKBOXES_ARCHIVE";
export const FORMAT_DATA_CHECKBOXES_REALTIME = "FORMAT_DATA_CHECKBOXES_REALTIME";

export const FORMAT_DATA_ARCHIVE = 'FORMAT_DATA_ARCHIVE';
export const FORMAT_DATA_REALTIME = 'FORMAT_DATA_REALTIME';

export const ML_CHECKBOXES_ARCHIVE = "ML_CHECKBOXES_ARCHIVE";
export const ML_ARCHIVE = 'ML_ARCHIVE';

export const REANALYSIS_CHECKBOXES_ARCHIVE = "REANALYSIS_CHECKBOXES_ARCHIVE";
export const REANALYSIS_ARCHIVE = 'REANALYSIS_ARCHIVE';

export const PARAMETERS_ADD_REALTIME = "PARAMETERS_ADD_REALTIME";
export const PARAMETERS_ADD_ARCHIVE = "PARAMETERS_ADD_ARCHIVE";

export const PARAMETERS_SELECT_REALTIME = "PARAMETERS_SELECT_REALTIME";
export const PARAMETERS_SELECT_ARCHIVE = "PARAMETERS_SELECT_ARCHIVE";

export const PARAMETERS_REMOVE_REALTIME = "PARAMETERS_REMOVE_REALTIME";
export const PARAMETERS_REMOVE_ARCHIVE = "PARAMETERS_REMOVE_ARCHIVE";

export const DATASETS_ADD_REALTIME = "DATASETS_ADD_REALTIME";
export const DATASETS_ADD_ARCHIVE = "DATASETS_ADD_ARCHIVE";

export const DATASETS_SELECT_REALTIME = "DATASETS_SELECT_REALTIME";
export const DATASETS_SELECT_ARCHIVE = "DATASETS_SELECT_ARCHIVE";

export const DATASETS_REMOVE_REALTIME = "DATASETS_REMOVE_REALTIME";
export const DATASETS_REMOVE_ARCHIVE = "DATASETS_REMOVE_ARCHIVE";

export const PARAMETERS_REMOVE_ALL_ARCHIVE  = "PARAMETERS_REMOVE_ALL_ARCHIVE";
export const DATASETS_REMOVE_ALL_ARCHIVE = "DATASETS_REMOVE_ALL_ARCHIVE";

export const PARAMETERS_REMOVE_ALL_REALTIME  = "PARAMETERS_REMOVE_ALL_REALTIME";
export const DATASETS_REMOVE_ALL_REALTIME = "DATASETS_REMOVE_ALL_REALTIME";

export const SEARCH_REALTIME  = "SEARCH_REALTIME";
export const SEARCH_ARCHIVE = "SEARCH_ARCHIVE";


export const SET_DETAILS_DATASET = "SET_DETAILS_DATASET";
export const SET_DETAILS_DATASET_SELECTION = "SET_DETAILS_DATASET_SELECTION";
export const ACTIVE_TAB_DETAILS = "ACTIVE_TAB_DETAILS";
export const SET_DETAILS_DATASET_RELATED = "SET_DETAILS_DATASET_RELATED";

/* DETAILS TAB DEFINITION */
export const OVERVIEW = "OVERVIEW";
export const DOWNLOAD_DATA = "DOWNLOAD_DATA";
export const DOCUMENTATION = "DOCUMENTATION";

/* LOGIN */

export const LOGGED = "LOGGED";
export const NOT_LOGGED = "NOT_LOGGED";

/* RESULT VISUALIZATION */

export const GRID = "GRID";
export const COLUMN = "COLUMN";


/* BASKET */
export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET";
export const ADD_BASKET_NAME = "ADD_BASKET_NAME";
export const EXPORT_BASKET_NAME = "EXPORT_BASKET_NAME";

/* ORDERS */
export const SUBMIT_ORDER = "SUBMIT_ORDER";