import React from 'react'
import { Button, Image, Modal, Popup } from 'semantic-ui-react'
import tooltip from '../../icon/tooltip.png'
import tooltipPrimary from '../../icon/tooltipPrimary.png'

function Tooltip(props) {
    const [open, setOpen] = React.useState(false)

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                
                <Popup 
                    hoverable
                    content={
                        <>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et massa nulla. 
                            In cursus mattis ante vel tristique. Interdum et malesuada fames ac 
                            ante ipsum primis in faucibus...</p>
                            <label className="Underline">Read more</label>
                        </>
                        
                    } 
                    trigger={
                        props.tooltip == "primary" ?
                        <img src={tooltipPrimary} alt='info' className={props.classes}></img>:
                        <img src={tooltip} alt='info' className={props.classes}></img>
                    }
                />
                
            }
            className={'p-3'}
        >
            <Modal.Header className={'border-none px-0 mt-3'}><h1>Tooltip title</h1></Modal.Header>
            <Modal.Description>
                <p>Vivamus tempus finibus nulla ac dignissim. Nullam pharetra dolor in faucibus fringilla. Praesent in dolor sollicitudin orci fermentum porta eget faucibus enim. Nam at faucibus purus. Donec ornare efficitur turpis quis dapibus. Sed eros tortor, ullamcorper nec mauris et, ultricies pulvinar neque. Vestibulum in dictum velit. Nullam vitae enim aliquam, auctor lacus quis, euismod neque. Morbi ante elit, laoreet sit amet velit sit amet, scelerisque eleifend nunc. Morbi sed leo eu sem lacinia pellentesque consectetur consectetur arcu. Vivamus posuere iaculis ligula a venenatis. Duis elementum, enim non mollis blandit, enim risus cursus mauris, et posuere erat dolor in quam. Suspendisse vitae lacus accumsan lacus tempus hendrerit. Maecenas euismod euismod erat fermentum volutpat. Nunc molestie hendrerit metus. Pellentesque volutpat massa in neque euismod, vitae convallis nunc ullamcorper. </p>
            </Modal.Description>
            
            <Modal.Actions>
                <Button primary onClick={() => setOpen(false)}>Ok</Button>
            </Modal.Actions>
            
        </Modal>
    )
}

export default Tooltip;