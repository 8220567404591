import './Price.css'
function BannerPrice(){
    return(
        <div className="banner-price">
            <div className="row banner-price__content">
                <div className="text-end">
                    <p><strong>Total daily volume <span className="ps-4">3.35 MB</span></strong></p>

                    <p><strong>Total data cost (EPUs*) <span className="ps-4">2,779.09</span></strong></p>
                </div>
            </div>
        </div>
    );
}

export default BannerPrice;