import './Card.css';
import periodIcon from '../../icon/periodIcon.png';
import parametersIcon from '../../icon/parametersIcon.png';
import provisioningIcon from '../../icon/provisioningIcon.png';
import formatdataIcon from '../../icon/formatdataIcon.png';
import tooltipPrimary from '../../icon/tooltipPrimary.png'
import { ARCHIVE, GRID } from '../../constDefinition';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import  Moment  from 'react-moment';
import { Icon, Label, Popup } from 'semantic-ui-react'
import { logged, setDetailsDataset } from '../../actions';
import ParametersModal from '../modal/ParametersModal';
import Tooltip from "../modal/Tooltip";

function Card(props) {
  console.log(props.visualisation)
  var MAX_DESCRIPTION_LENGTH 
  props.visualisation == GRID ? MAX_DESCRIPTION_LENGTH = 140 : MAX_DESCRIPTION_LENGTH = Number.MAX_SAFE_INTEGER
  var MAX_PARAMETERS_LENGTH 
  props.visualisation == GRID ? MAX_PARAMETERS_LENGTH = 40 : MAX_PARAMETERS_LENGTH = 110
  var dispatch = useDispatch()
  var item = props.item

  const store = useSelector(state => props.form === ARCHIVE ? state.archiveForm : state.realTimeForm)
  const isLogged = useSelector(state => state.isLogged.logged)

  function getColor(alternative){

    if(alternative.code == 'public'){
      return 'available'
    }
    else if(!isLogged){
      return 'disabled'
    }
    else{
      return 'unavailable'
    }
  }

  var provisioningActiveLabel
  var provisioningNotActiveLabel
  var encodingActiveLabel
  var encodingNotActiveLabel



  if(props.form === ARCHIVE){
    Object.values(store.provisioningArchive).map(delivery => {
      var indexProvisioning = item.delivery.indexOf(delivery.code)
      if(indexProvisioning != -1){
        if(delivery.status){
          if(provisioningActiveLabel){
            provisioningActiveLabel = provisioningActiveLabel +", "+store.provisioningArchive[delivery.code].title
          }
          else{
            provisioningActiveLabel = store.provisioningArchive[delivery.code].title
          }
        }
        else{
          if(provisioningNotActiveLabel){
            provisioningNotActiveLabel = provisioningNotActiveLabel +", "+store.provisioningArchive[delivery.code].title
          }
          else{
            provisioningNotActiveLabel = store.provisioningArchive[delivery.code].title
          }
        }
      }
    })

    Object.values(store.formatDataArchive).map(encoding => {
      var indexEncoding = item.encoding.indexOf(encoding.code)
      if(indexEncoding != -1){
        if(encoding.status){
          if(encodingActiveLabel){
            encodingActiveLabel = encodingActiveLabel +", "+store.formatDataArchive[encoding.code].title
          }
          else{
            encodingActiveLabel = store.formatDataArchive[encoding.code].title
          }
        }
        else{
          if(encodingNotActiveLabel){
            encodingNotActiveLabel = encodingNotActiveLabel +", "+store.formatDataArchive[encoding.code].title
          }
          else{
            encodingNotActiveLabel = store.formatDataArchive[encoding.code].title
          }
        }
      }
    })
  }
  else{
    Object.values(store.provisioningRealtime).map(delivery => {
      var indexProvisioning = item.delivery.indexOf(delivery.code)
      if(indexProvisioning != -1){
        if(delivery.status){
          if(provisioningActiveLabel){
            provisioningActiveLabel = provisioningActiveLabel +", "+store.provisioningRealtime[delivery].title
          }
          else{
            provisioningActiveLabel = store.provisioningRealtime[delivery].title
          }
        }
        else{
          if(provisioningNotActiveLabel){
            provisioningNotActiveLabel = provisioningNotActiveLabel +", "+store.provisioningRealtime[delivery.code].title
          }
          else{
            provisioningNotActiveLabel = store.provisioningRealtime[delivery.code].title
          }
        }
      }
    })

    Object.values(store.formatDataRealtime).map(encoding => {
      var indexEncoding = item.encoding.indexOf(encoding.code)
      if(indexEncoding != -1){
        if(encoding.status){
          if(encodingActiveLabel){
            encodingActiveLabel = encodingActiveLabel +", "+store.formatDataRealtime[encoding.code].title
          }
          else{
            encodingActiveLabel = store.formatDataRealtime[encoding.code].title
          }
        }
        else{
          if(encodingNotActiveLabel){
            encodingNotActiveLabel = encodingNotActiveLabel +", "+store.formatDataRealtime[encoding.code].title
          }
          else{
            encodingNotActiveLabel = store.formatDataRealtime[encoding.code].title
          }
        }
      }
    })
  }
  var parametersLength = 0
  var seeAll = false
  var matchingFilter = item.variables.filter(variable => item.matching_variable_ids.indexOf(variable.id) != -1);
  var notMatchingFilter = item.variables.filter(variable => item.matching_variable_ids.indexOf(variable.id) == -1);
  return (
      <div className="card">
        <h3><b>{item.title}</b></h3>
        <p style={{marginTop: 20}}>
          {item.description.length > MAX_DESCRIPTION_LENGTH ? item.description.substring(0,MAX_DESCRIPTION_LENGTH) + " ...": item.description}
        </p>

        {
          props.form === ARCHIVE ?
            <div class="d-flex flex-row">
              <div class="p-2 ResultsIcon"><img src={periodIcon} alt='info' className=""></img></div>
              <div class="p-2">Period:</div>
              <div class="p-2"><b>From <Moment format="MMMM YYYY">{item.start_date}</Moment> to <Moment format="MMMM YYYY">{item.end_date}</Moment></b></div>
            </div>
            : <div></div>
        }

        <div class="d-flex flex-row">
          <div class="p-2 ResultsIcon"><img src={parametersIcon} alt='info' className=""></img></div>
          <div class="p-2">Parameters:</div>
          <div class="p-2">
            {
              matchingFilter.map(
                variable => {
                  if(parametersLength + variable.shortname.length +1 > MAX_PARAMETERS_LENGTH && !seeAll){
                    seeAll = true
                    return <span> <ParametersModal readOnly={true} items={matchingFilter}></ParametersModal></span>
                  }
                  else if(parametersLength + variable.shortname.length +1 <= MAX_PARAMETERS_LENGTH && !seeAll){
                    parametersLength = parametersLength + variable.shortname.length +1
                    return <Popup content={variable.name} trigger={
                      <span><b>{variable.shortname}, </b></span>
                    } />
                  }
                }
              )
            }
            {
              notMatchingFilter.map(
                variable => {
                  if(parametersLength + variable.shortname.length +1 > MAX_PARAMETERS_LENGTH && !seeAll){
                    seeAll = true
                    return <span> <ParametersModal readOnly={true} items={notMatchingFilter}></ParametersModal></span>
                  }
                  else if(parametersLength + variable.shortname.length +1 <= MAX_PARAMETERS_LENGTH && !seeAll){
                    parametersLength = parametersLength + variable.shortname.length +1
                    return <Popup content={variable.name} trigger={
                      <span>{variable.shortname}, </span>
                    } />
                  }
                }
              )
            }
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-2 ResultsIcon"><img src={provisioningIcon} alt='info' className=""></img></div>
          <div class="p-2">Provisioning:</div>
          <div class="p-2"><b>{provisioningActiveLabel}</b><span>{provisioningNotActiveLabel ? provisioningActiveLabel ? ", "+provisioningNotActiveLabel:provisioningNotActiveLabel : ""}</span></div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-2 ResultsIcon"><img src={formatdataIcon} alt='info' className=""></img></div>
          <div class="p-2">Format of Data:</div>
          <div class="p-2"><b>{encodingActiveLabel}</b><span>{encodingNotActiveLabel ? encodingActiveLabel ? ", "+encodingNotActiveLabel:encodingNotActiveLabel : ""}</span></div>
        </div>

        <label className="ResultsDividerAccess"><span>ACCESS TYPE</span></label>

        <div class="d-flex flex-row justify-content-start">

          {item.access.map(access => {
              var accessLabel
              var enabled
              if(props.form === ARCHIVE){
                accessLabel = store.accessArchive[access].title
                enabled = getColor(store.accessArchive[access]) 
              }
              else{
                accessLabel = store.accessRealtime[access].title
                enabled = getColor(store.accessRealtime[access])
              }
  
              return (
                <div className="col-md-4">
                  <div className="IconLabelStart">
                    <Tooltip tooltip="primary" classes=""/>
                    <label className={enabled}>{accessLabel}</label>
                  </div>
                </div>
              )
            
            }
            )} 
        </div>

        <div className="CardCTA">
          <Link to="/details">
              <Button primary onClick={() => {
                    dispatch(setDetailsDataset(item))
                  }}
                >
                  Details
              </Button>
          </Link>
        </div>

        
      </div>
    );
  }
  
  export default Card;
  