import { CardActions } from "@material-ui/core"
import axios from "axios"
import { 
    ARCHIVE_TAB_SELECT, 
    REALTIME_TAB_SELECT, 
    ACCESS_REALTIME, 
    ACCESS_ARCHIVE, 
    PERIOD_SLIDER_SELECT,
    PERIOD_INPUT_SELECT,
    PERIOD_SLIDER_SELECT_RADIO,
    PERIOD_INPUT_SELECT_RADIO,
    ACCESS_CHECKBOXES_ARCHIVE,
    ACCESS_CHECKBOXES_REALTIME,
    PROVISIONING_REALTIME,
    PROVISIONING_ARCHIVE,
    PROVISIONING_CHECKBOXES_REALTIME,
    PROVISIONING_CHECKBOXES_ARCHIVE,
    ML_CHECKBOXES_ARCHIVE,
    ML_CHECKBOXES_REALTIME,
    ML_ARCHIVE,
    ML_REALTIME,
    FORMAT_DATA_REALTIME,
    FORMAT_DATA_ARCHIVE,
    FORMAT_DATA_CHECKBOXES_ARCHIVE,
    FORMAT_DATA_CHECKBOXES_REALTIME,
    PARAMETERS_SELECT_ARCHIVE,
    PARAMETERS_SELECT_REALTIME,
    PARAMETERS_REMOVE_REALTIME,
    PARAMETERS_REMOVE_ARCHIVE,
    DATASETS_SELECT_ARCHIVE,
    DATASETS_SELECT_REALTIME,
    DATASETS_REMOVE_ARCHIVE,
    DATASETS_REMOVE_REALTIME,
    DATASETS_REMOVE_ALL_REALTIME,
    DATASETS_REMOVE_ALL_ARCHIVE,
    PARAMETERS_REMOVE_ALL_ARCHIVE,
    PARAMETERS_REMOVE_ALL_REALTIME,
    REANALYSIS_CHECKBOXES_ARCHIVE,
    REANALYSIS_ARCHIVE,
    PARAMETERS_ADD_ARCHIVE,
    PARAMETERS_ADD_REALTIME,
    DATASETS_ADD_ARCHIVE,
    DATASETS_ADD_REALTIME,
    SEARCH_ARCHIVE,
    SEARCH_REALTIME,
    REALTIME_KEYWORD_SELECT,
    ARCHIVE_KEYWORD_SELECT,
    RESET_REALTIME,
    RESET_ARCHIVE,
    PERIOD_SLIDER_DEFAULT,
    PERIOD_INPUT_DEFAULT,
    START_LOADING_REALTIME,
    START_LOADING_ARCHIVE,
    SET_DETAILS_DATASET,
    ACTIVE_TAB_DETAILS,
    LOGGED,
    NOT_LOGGED,
    CHANGE_PAGE_REALTIME,
    CHANGE_PAGE_ARCHIVE,
    SET_DETAILS_DATASET_RELATED,
    ADD_TO_BASKET,
    REMOVE_FROM_BASKET,
    SUBMIT_ORDER,
    ADD_BASKET_NAME,
    SET_DETAILS_DATASET_SELECTION
} from "../constDefinition"
import { baseURL } from "../services/constDefinitionService"

/* SEARCH FORM TAB */
export const realTimeActiveTab = () => {
    return {
        type: REALTIME_TAB_SELECT
    }
}

export const archiveActiveTab = () => {
    return {
        type: ARCHIVE_TAB_SELECT
    }
}

/* START LOADING */
export const startLoadingRealtime = () => {
    return {
        type: START_LOADING_REALTIME
    }
}

export const startLoadingArchive = () => {
    return {
        type: START_LOADING_ARCHIVE
    }
}

/* RESET FORM */
export const realTimeReset = () => {
    return {
        type: RESET_REALTIME
    }
}

export const archiveReset = () => {
    return {
        type: RESET_ARCHIVE
    }
}

/* KEYWORD */
export const selectKeywordRT = (value) => {
    return {
        type: REALTIME_KEYWORD_SELECT,
        value: value,
    }
}

export const selectKeywordARC = (value) => {
    return {
        type: ARCHIVE_KEYWORD_SELECT,
        value: value,
    }
}

/* PERIOD DEFAULT VALUE FOR INPUT AND SLIDER */
export const periodDefault = () => {
    return (dispatch, getState) => {       
        axios({
            method: 'GET',
            url: baseURL + 'period',
        }).then(res => {
            dispatch({
                type: PERIOD_INPUT_DEFAULT,
                value: res.data["end_year"]
            })
            dispatch({
                type: PERIOD_SLIDER_DEFAULT,
                value: res.data
            })
        })
    }
}

/* PERIOD RADIO SELECTION*/

export const periodInputRadio = () => {
    return {
        type: PERIOD_INPUT_SELECT_RADIO
    }
}

export const periodSliderRadio = () => {
    return {
        type: PERIOD_SLIDER_SELECT_RADIO
    }
}

/* PERIOD INPUT OR SLIDER VALUE  */

export const periodInput = (value) => {
    return {
        type: PERIOD_INPUT_SELECT,
        value: value
    }
}

export const periodSlider = (value) => {
    return {
        type: PERIOD_SLIDER_SELECT,
        value: value
    }
}
/* DATASETS OPTION */
export const addDatasetsArchive = (item) => {
    return {
        type: DATASETS_ADD_ARCHIVE,
        item: item
    }
}

export const addDatasetsRealTime = (item) => {
    return {
        type: DATASETS_ADD_REALTIME,
        item: item
    }
}

export const setDatasetsArchive = (items, options) => {
    return {
        type: DATASETS_SELECT_ARCHIVE,
        items: items,
        options: options
    }
}

export const setDatasetsRealTime = (items, options) => {
    return {
        type: DATASETS_SELECT_REALTIME,
        items: items,
        options: options
    }
}

export const removeDatasetsArchive = (item) => {
    return {
        type: DATASETS_REMOVE_ARCHIVE,
        item: item,
    }
}

export const removeDatasetsRealTime = (item) => {
    return {
        type: DATASETS_REMOVE_REALTIME,
        item: item
    }
}

export const removeAllDatasetsArchive = () => {
    return {
        type: DATASETS_REMOVE_ALL_ARCHIVE
    }
}

export const removeAllDatasetsRealTime = () => {
    return {
        type: DATASETS_REMOVE_ALL_REALTIME
    }
}

/* PARAMETER OPTION */
export const addParametersArchive = (item) => {
    return {
        type: PARAMETERS_ADD_ARCHIVE,
        item: item
    }
}

export const addParametersRealTime = (item) => {
    return {
        type: PARAMETERS_ADD_REALTIME,
        item: item
    }
}

export const setParametersArchive = (items, options) => {
    return {
        type: PARAMETERS_SELECT_ARCHIVE,
        items: items,
        options: options
    }
}

export const setParametersRealTime = (items, options) => {
    return {
        type: PARAMETERS_SELECT_REALTIME,
        items: items,
        options: options
    }
}

export const removeParameterArchive = (item) => {
    return {
        type: PARAMETERS_REMOVE_ARCHIVE,
        item: item,
    }
}

export const removeParameterRealTime = (item) => {
    return {
        type: PARAMETERS_REMOVE_REALTIME,
        item: item
    }
}

export const removeAllParameterArchive = () => {
    return {
        type: PARAMETERS_REMOVE_ALL_ARCHIVE
    }
}

export const removeAllParameterRealTime = () => {
    return {
        type: PARAMETERS_REMOVE_ALL_REALTIME
    }
}

/* ACCESS CHECKBOXES*/
export const setAccessArchiveCheckboxes = (value) => {
    return {
        type: ACCESS_CHECKBOXES_ARCHIVE,
        value: value
    }
}

export const setAccessRealTimeCheckboxes = (value) => {
    return {
        type: ACCESS_CHECKBOXES_REALTIME,
        value: value
    }
}


export const selectAccessARC = (code) => {
    return {
        type: ACCESS_ARCHIVE,
        code: code,
    }
}

export const selectAccessRT = (code) => {
    return {
        type: ACCESS_REALTIME,
        code: code
    }
}

/* PROVISIONING CHECKBOXES */
export const setProvisioningArchiveCheckboxes = (value) => {
    return {
        type: PROVISIONING_CHECKBOXES_ARCHIVE,
        value: value
    }
}

export const setProvisioningRealTimeCheckboxes = (value) => {
    return {
        type: PROVISIONING_CHECKBOXES_REALTIME,
        value: value
    }
}


export const selectProvisioningARC = (code) => {
    return {
        type: PROVISIONING_ARCHIVE,
        code: code,
    }
}

export const selectProvisioningRT = (code) => {
    return {
        type: PROVISIONING_REALTIME,
        code: code
    }
}


/* FORMAT OF DATA CHECKBOXES */

export const setFormatDataArchiveCheckboxes = (value) => {
    return {
        type: FORMAT_DATA_CHECKBOXES_ARCHIVE,
        value: value
    }
}

export const setFormatDataRealTimeCheckboxes = (value) => {
    return {
        type: FORMAT_DATA_CHECKBOXES_REALTIME,
        value: value
    }
}


export const selectFormatDataARC = (code) => {
    return {
        type: FORMAT_DATA_ARCHIVE,
        code: code,
    }
}

export const selectFormatDataRT = (code) => {
    return {
        type: FORMAT_DATA_REALTIME,
        code: code
    }
}

/* MACHINE LEARNING CHECKBOXES */
export const setMachineLearningArchiveCheckboxes = (value) => {
    return {
        type: ML_CHECKBOXES_ARCHIVE,
        value: value
    }
}


export const selectMachineLearningARC = (code) => {
    return {
        type: ML_ARCHIVE,
        code: code,
    }
}


/* REANALYSIS CHECKBOXES */
export const setReanalysisArchiveCheckboxes = (value) => {
    return {
        type: REANALYSIS_CHECKBOXES_ARCHIVE,
        value: value
    }
}


export const selectReanalysisARC = (code) => {
    return {
        type: REANALYSIS_ARCHIVE,
        code: code,
    }
}



/* SEARCH RESULTS */

export const searchArchive = (query) => {

    return (dispatch, getState) => {
        let cancel        
        axios({
            method: 'GET',
            url: baseURL + 'search',
            params: query,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {
            dispatch({
                type: SEARCH_ARCHIVE,
                results: res.data 
            })
        }).catch(e => {
            if (axios.isCancel(e)) return
        })
    }
}

export const searchRealtime = (query) => {
    let cancel
    return (dispatch, getState) => {
        let cancel        
        axios({
            method: 'GET',
            url: baseURL + 'search',
            params: query,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {
            dispatch({
                type: SEARCH_REALTIME,
                results: res.data 
            })
        }).catch(e => {
            if (axios.isCancel(e)) return
        })
    }
}

export const setPageRealtime = (value) => {
    return {
        type: CHANGE_PAGE_REALTIME,
        value: value,
    }
}

export const setPageArchive = (value) => {
    return {
        type: CHANGE_PAGE_ARCHIVE,
        value: value,
    }
}

export const setDetailsDatasetOffline = (dataset, selection) => {
    return {
        type: SET_DETAILS_DATASET_SELECTION,
        value: dataset,
        selection: selection
    }
}

export const setDetailsDataset = (dataset) => {
    return (dispatch, getState) => {       
        axios({
            method: 'GET',
            url: dataset.url,
        }).then(res => {
            dispatch({
                type: SET_DETAILS_DATASET,
                value: res.data
            })
            axios({
                method: 'GET',
                url: baseURL+"search?limit=4",
            }).then(resRelated => {
                dispatch({
                    type: SET_DETAILS_DATASET_RELATED,
                    results: resRelated.data.results
                })
            })
            
        })
    }
}

export const setActiveTabDetails = (value) => {
    return {
        type: ACTIVE_TAB_DETAILS,
        value: value,
    }
}

export const logged = () => {
    return {
        type: LOGGED,
    }
}

export const notLogged = () => {
    return {
        type: NOT_LOGGED,
    }
}

export const updateVisulisations = (type) => {
    return {
        type: type
    }
}

export const addToBasket = (requestName, dataset, selection, version) => {
    return {
        type: ADD_TO_BASKET,
        requestName : requestName,
        dataset : dataset,
        selection : selection,
        version: version
    }
}

export const removeFromBasket = (id) => {
    return {
        type: REMOVE_FROM_BASKET,
        id: id
    }
}

export const submitOrder = (orderName, requestItems, status) => {
    return {
        type: SUBMIT_ORDER,
        orderName: orderName,
        requestItems: requestItems,
        status: status
    }
}

export const addBasketName = (basketName)=>{
    return{
        type : ADD_BASKET_NAME,
        basketName : basketName
    }
}