import { REALTIME } from "../../constDefinition";
import Keywords from '../search-form/search-form-components/Keywords';
import Parameters from '../search-form/search-form-components/Parameters';
import CheckboxFilters from '../search-form/search-form-components/CheckboxFilters';

function ResultsRealTimeSidebar() {

    return (
      <div>
        <h2>Refine your search</h2>
        <Keywords form={REALTIME} view="results"/>
        <Parameters view="results"/>
        <CheckboxFilters view="results" form={REALTIME}/>
      </div>
    );
  }
  
  export default ResultsRealTimeSidebar;
  