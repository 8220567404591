import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccordionItem from './AccordionItem';
import { Button } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function RightSidebar(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let accordionItem = props.sidebarSections.map( (sidebar, i) => <AccordionItem key={i} expanded={expanded} onChange={handleChange} ariaControls={'panel' + (i+1) + 'bh-content'} panel={'panel' + (i+1)} title={sidebar.title} id={'panel' + (i+1) + 'bh-header'}/>)


  return (
    <div className={classes.root}>

      {accordionItem}

      <div className="TicketSupport">
          <h3>Ticket support</h3>
          <Button>Open</Button>
      </div>
    </div>
  );
}