import './search-form.css';
import Keywords from './search-form-components/Keywords';
import Parameters from './search-form-components/Parameters';
import CheckboxFilters from './search-form-components/CheckboxFilters';
import { Button } from 'semantic-ui-react'
import { REALTIME } from "../../constDefinition"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { periodSlider, realTimeReset } from '../../actions';


function RealTimeForm() {
  const dispatch = useDispatch()
  return (
    <div className="box SearchForm">
      <h2>These are the medium, extended, and long range forecast data produced by our current model.</h2>
      <Keywords form={REALTIME} view="search"/>
      <Parameters form={REALTIME} view="search"/>
      <CheckboxFilters view="search" form={REALTIME}/>
      <div className="SeachForm-CTA">
          <Button secondary
            onClick={() => {
                dispatch(periodSlider())
                dispatch(realTimeReset())
              }}>Reset</Button>
          <Link to="/results">
            <Button primary>
                Search
            </Button>
          </Link>
      </div>
    </div>
  );
}

export default RealTimeForm;
