import { ADD_TO_BASKET, REMOVE_FROM_BASKET, SUBMIT_ORDER, ADD_BASKET_NAME } from "../constDefinition"


const initial_state = {
    basket : [],
    basketName : '',
    orders : []
}

const ordersReducer = (state = initial_state, action) => {
    switch(action.type) {
        case ADD_TO_BASKET:
            var requestName = action.requestName
            var dataset = action.dataset
            var selection = action.selection
            var version = action.version
            var newBasket = []
            newBasket = Object.assign(newBasket, state.basket)
            newBasket.push({'requestName': requestName, 'dataset': dataset, 'selection': selection, 'version': version})
            state.basket = newBasket
            return state
        case REMOVE_FROM_BASKET:
            var id =  action.id
            var newBasket = []
            newBasket = Object.assign(newBasket, state.basket)
            newBasket.splice(id, 1);
            state.basket = newBasket
            if(state.basket.length === 0){
                state.basketName = '';
            }
            return state
        case SUBMIT_ORDER:
            var orderName = action.orderName
            var requestItems = action.requestItems
            var status = action.status
            var newOrders = []
            newOrders = Object.assign(newOrders, state.orders)
            state.orders = newOrders
            var newOrder = {'status': status, 'items': requestItems, 'orderName': orderName}
            newOrders.push(newOrder);

            if(requestItems[0].version != 'public'){
                state.basketName = '';
                state.basket = [];
            }

            return state;
        case ADD_BASKET_NAME:
            let basketName = action.basketName;
            state.basketName = basketName;
            return state;
        default:
            return state;
    }
}

export default ordersReducer;