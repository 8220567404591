import React from "react";
import BasketItem from "../item-components/BasketItem";

function OrderDetails(props) {

    const order = props.location.state.item.items;
    const indexOrder = props.location.state.index;
    return (
        <div className="Page">

            <h1 className="mb-5">Your order details</h1>

            {order.map((el, index) => <BasketItem key={index} item={el} index={index} indexOrder={indexOrder} header={'order-details'}/>)}

        </div>
    );
}

export default OrderDetails;