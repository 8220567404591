import { useSelector, useDispatch } from 'react-redux';
import { realTimeActiveTab, archiveActiveTab } from '../../actions';
import RealTimeForm from '../search-form/RealTimeForm';
import ArchiveForm from '../search-form/ArchiveForm';
import RightSidebar from '../right-sidebar/RightSidebar';
import {ARCHIVE, REALTIME} from '../../constDefinition';

function SearchFormPage() {
  const tabSelected = useSelector(state => state.activeTab)
  const dispatch = useDispatch();

  let sidebarSections = [
    {title : 'How it works'},
    {title : 'Datasets'},
    {title : 'Licences'},
    {title : 'Documentation'},
    {title : 'Tutorial'},
    {title : 'Software'}
  ]

    return (
      <div className="Page">
          <div className="row">
            <div className="col-md-8 col-sm-12" style={{paddingRight: 25}}>
              <h1 style={{marginBottom: "50px"}}>Dataset</h1>
              <button className={(tabSelected === REALTIME ? 'SearchFormTabButtonEnabled' : 'SearchFormTabButtonDisabled')} onClick={() => dispatch(realTimeActiveTab())}>Real Time</button>
              <button className={(tabSelected === ARCHIVE ? 'SearchFormTabButtonEnabled' : 'SearchFormTabButtonDisabled')} onClick={() => dispatch(archiveActiveTab())}>Archive</button>
              
              {tabSelected === REALTIME ? (
                <RealTimeForm />
              ):
              (
                <ArchiveForm />
              )
              }
            </div>
            <div className="col-md-4 col-sm-12" style={{paddingLeft: 25}}>
              <RightSidebar sidebarSections={sidebarSections}/>
            </div>
          </div>
          
      </div>
    );
  }
  
  export default SearchFormPage;
  