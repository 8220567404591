import './DownloadData.css'
import { ARCHIVE } from '../../constDefinition';
import { useDispatch, useSelector } from 'react-redux';
import Card from "./Card";
import { Button, Input, Label, Popup } from 'semantic-ui-react'
import { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LoginBanner from "../item-components/LoginBanner"
import { Checkbox } from 'semantic-ui-react';
import tooltip from "../../icon/tooltipPrimary.png";
import { addToBasket } from '../../actions';
import RequestToBasket from "../modal/RequestToBasket";
import RequestSuccessfully from "../modal/RequestSuccessfully";
import RequestProcessed from "../modal/RequestProcessed";
import { Toolbar } from '@material-ui/core';
import Tooltip from "../modal/Tooltip";


const initial_form = {
    observedParameter: {
        option1:{code: "option1", title: "Characteristic of pressure tendency", status:false, enabled:true},
        option2:{code: "option2", title: "Cloud base height", status:false, enabled:true},
        option3:{code: "option2", title: "Geopotential", status:false, enabled:true},
        option4:{code: "option3", title: "Low cloud amount", status:false, enabled:true},
        option5:{code: "option5", title: "Past weather", status:false, enabled:true},
        option6:{code: "option6", title: "Past weather 2", status:false, enabled:true},
        option7:{code: "option7", title: "Present weather", status:false, enabled:true},
        option8:{code: "option8", title: "Sea water temperature", status:false, enabled:true},
        option9:{code: "option9", title: "Ship direction", status:false, enabled:true},
        option10:{code: "option10", title: "Ship speed", status:false, enabled:true},
        option11:{code: "option12", title: "Surface pressure", status:false, enabled:true},
        option12:{code: "option12", title: "Surface pressure tendency", status:false, enabled:true},
        option13:{code: "option13", title: "Surface wind direction", status:false, enabled:true},
        option14:{code: "option14", title: "Surface wind speed", status:false, enabled:true},
        option15:{code: "option15", title: "Ten-metre meridional wind", status:false, enabled:true},
        option16:{code: "option16", title: "Ten-metre zonal wind", status:false, enabled:true},
        option17:{code: "option17", title: "Total cloud amount", status:false, enabled:true},
        option18:{code: "option18", title: "Two-metre dew point", status:false, enabled:true},
        option19:{code: "option19", title: "Two-metre relative humidity", status:false, enabled:true},
        option20:{code: "option20", title: "Two-metre temperature", status:false, enabled:true},
        option21:{code: "option21", title: "Type of high clouds", status:false, enabled:true},
        option22:{code: "option22", title: "Type of low clouds", status:false, enabled:true},
        option23:{code: "option23", title: "Type of middle clouds", status:false, enabled:true},
        option24:{code: "option24", title: "Visibility", status:false, enabled:true},
    },
    source: {
        option1:{code: "option1", title: "ICOADS25", status:false, enabled:true},
        option2:{code: "option2", title: "ISPD3.26", status:false, enabled:true}
    },
    observationUsage:{
        option1:{code: "option1", title: "Assimilated", status:false, enabled:true},
        option2:{code: "option2", title: "Rejected by blacklist", status:false, enabled:true},
        option3:{code: "option2", title: "Rejected by variational quality control", status:false, enabled:true},
        option4:{code: "option3", title: "Rejected for another reason", status:false, enabled:true},
    },
    observationEvents:{
        option1:{code: "option1", title: "None", status:false, enabled:true},
        option2:{code: "option2", title: "combined_flagging and fg2big", status:false, enabled:true},
        option3:{code: "option2", title: "combined_flagging, vertco_type_changed, and fg2big", status:false, enabled:true},
        option4:{code: "option3", title: "not_analysis_varno, obs_error2big, fg_missing, and vertco_missing", status:false, enabled:true},
        option5:{code: "option5", title: "obs_error2big, fg_missing, and vertco_missing", status:false, enabled:true},
        option6:{code: "option6", title: "obserror_increased and bad_practice", status:false, enabled:true},
        option7:{code: "option7", title: "obserror_increased and fg2big", status:false, enabled:true},
        option8:{code: "option8", title: "obserror_increased and obs_error2big", status:false, enabled:true},
        option9:{code: "option9", title: "obserror_increased and varqc_performed", status:false, enabled:true},
        option10:{code: "option10", title: "obserror_increased, fg2big, and bad_practice", status:false, enabled:true},
        option11:{code: "option12", title: "obserror_increased, not_analysis_varno, and fg2big", status:false, enabled:true},
        option12:{code: "option12", title: "obserror_increased, obs_error2big, fg_missing, and vertco_missing", status:false, enabled:true},
        option13:{code: "option13", title: "obserror_increased, varqc_performed, and fg2big", status:false, enabled:true},
        option14:{code: "option14", title: "report_rejected and fg2big", status:false, enabled:true},
        option15:{code: "option15", title: "report_rejected, vertco_type_changed, and fg2big", status:false, enabled:true},
        option16:{code: "option16", title: "varqc_performed and fg2big", status:false, enabled:true},
        option17:{code: "option17", title: "varqc_performed, vertco_type_changed, and fg2big", status:false, enabled:true},
        option18:{code: "option18", title: "vertco_type_changed and duplicate", status:false, enabled:true},
        option19:{code: "option19", title: "vertco_type_changed, duplicate, and fg2big", status:false, enabled:true},
        option20:{code: "option20", title: "combined_flagging", status:false, enabled:true},
        option21:{code: "option21", title: "combined_flagging and vertco_type_changed", status:false, enabled:true},
        option22:{code: "option22", title: "fg2big", status:false, enabled:true},
        option23:{code: "option23", title: "obs_error2big and fg_missing", status:false, enabled:true},
        option24:{code: "option24", title: "obserror_increased", status:false, enabled:true},
        option25:{code: "option25", title: "obserror_increased and duplicate", status:false, enabled:true},
        option26:{code: "option26", title: "obserror_increased and not_analysis_varno", status:false, enabled:true},
        option27:{code: "option27", title: "obserror_increased and report_rejected", status:false, enabled:true},
        option28:{code: "option28", title: "obserror_increased, duplicate, and fg2big", status:false, enabled:true},
        option29:{code: "option29", title: "obserror_increased, fg_missing, and vertco_missing", status:false, enabled:true},
        option30:{code: "option30", title: "obserror_increased, obs_error2big, bad_practice, fg_missing, and vertco_missing", status:false, enabled:true},
        option31:{code: "option31", title: "obserror_increased, report_rejected, and fg2big", status:false, enabled:true},
        option32:{code: "option32", title: "report_rejected and vertco_type_changed", status:false, enabled:true},
        option33:{code: "option33", title: "varqc_performed", status:false, enabled:true},
        option34:{code: "option34", title: "varqc_performed and vertco_type_changed", status:false, enabled:true},
        option35:{code: "option35", title: "vertco_type_changed", status:false, enabled:true},
        option36:{code: "option36", title: "vertco_type_changed and fg2big", status:false, enabled:true},
        option37:{code: "option37", title: "vertco_type_changed, obs_error2big, and fg_missing", status:false, enabled:true},
    },
    observationPlatform:{
        option1:{code: "option1", title: "Coastal Or Island Station", status:false, enabled:true},
        option2:{code: "option2", title: "Coastal-Marine Automated Network CMAN", status:false, enabled:true},
        option3:{code: "option2", title: "DRIBU", status:false, enabled:true},
        option4:{code: "option3", title: "Expandable Bathythermograph XBT", status:false, enabled:true},
        option5:{code: "option5", title: "Fixed Ocean Platform Or Rig", status:false, enabled:true},
        option6:{code: "option6", title: "High Resolution Conductivity Temperature Depth CTD And XCTD", status:false, enabled:true},
        option7:{code: "option7", title: "Ocean Bottle And Low Resolution Conductivity Temperature Depth CTD And XCTD", status:false, enabled:true},
        option8:{code: "option8", title: "Mechanical Or Digital Or Micro Bathythermograph MBT", status:false, enabled:true},
        option9:{code: "option9", title: "Ocean Station Vessel Off Station", status:false, enabled:true},
    },
    observationPlatformLicensed:{
        option1:{code: "option1", title: "Ocean Station Vessel On Station", status:false, enabled:false},
        option2:{code: "option2", title: "SHIP", status:false, enabled:false},
        option3:{code: "option3", title: "Station Or Ship On Ice", status:false, enabled:false},
        option4:{code: "option4", title: "Tropical cyclone bogus", status:false, enabled:false},
    }
}

const CustomRadio = withStyles({
    root: {
      color: '#3855B3',
      '&$checked': {
        color: '#3855B3',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

function DownloadData(props) {
    const dispatch = useDispatch()
    var isLogged = useSelector(state => state.isLogged.logged)
    var item = useSelector(state => state.datasetDetails.dataset)
    var selection = useSelector(state => state.datasetDetails.selection)
    var nature = item ?item.nature[0]:ARCHIVE
    const storeProvisioning = useSelector(state => nature === ARCHIVE ? state.archiveForm.provisioningArchive : state.realTimeForm.provisioningRealtime)
    const storeEncoding = useSelector(state => nature === ARCHIVE ? state.archiveForm.formatDataArchive : state.realTimeForm.formatDataRealtime)
    const storeAccess = useSelector(state => nature === ARCHIVE ? state.archiveForm.accessArchive : state.realTimeForm.accessRealtime)
    const [requestName, setRequestName] = useState("")
    const [provisioning, setProvisioning] = useState(0)
    const [encoding, setEncoding] = useState(0)
    const [form, setForm] = useState(Object.keys(selection).length > 0 ? selection : initial_form)
    
    //const [form, setForm] = useState(initial_form)
    const [fieldEmpty, handlerFieldEmpty] = useState(false)

    const handleChangeInput = (e) => {
        setRequestName(e.target.value)
        handlerFieldEmpty(false)
    }
    const fieldValidation = (name)=>{
        handlerFieldEmpty(!fieldEmpty)
    }
    return (
        <>
        <Card>
            <div className="boxes-form">
                <p><b>DISCLAIMER: This form does not exactly match the specific form for the dataset. It's a dummy form just to show how the system should works.</b></p>
            </div>
            <div className="boxes-form">
                <div className="d-flex justify-content-between">
                    <h3><b>Observed parameter</b></h3>
                    <div className="boxes-form__actions">
                        <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observedParameter).map((key) => 
                                newForm.observedParameter[key].status = false
                            )
                            setForm(newForm)
                        }}>reset</span> | <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observedParameter).map((key) => 
                                newForm.observedParameter[key].enabled ?
                                newForm.observedParameter[key].status = true :
                                newForm.observedParameter[key].status = false
                            )
                            setForm(newForm)
                    }}>select all</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {Object.keys(form.observedParameter).map((key) => {
                            return(
                                <div className="col-6">
                                    <Checkbox 
                                        label={{ children: form.observedParameter[key].title }} 
                                        onChange={() => {
                                            var newForm = {}
                                            newForm = Object.assign(newForm, form)
                                            newForm.observedParameter[key].status = !newForm.observedParameter[key].status
                                            if(["option1","option2","option3","option4"].indexOf(key) != -1 && newForm.observedParameter[key].status){
                                                newForm.observedParameter["option15"].enabled = false
                                                newForm.observedParameter["option16"].enabled = false
                                                newForm.observedParameter["option17"].enabled = false
                                                newForm.observedParameter["option18"].enabled = false
                                                newForm.observedParameter["option15"].status = false
                                                newForm.observedParameter["option16"].status = false
                                                newForm.observedParameter["option17"].status = false
                                                newForm.observedParameter["option18"].status = false
                                            }
                                            else if(["option1","option2","option3","option4"].indexOf(key) != -1 && !newForm.observedParameter[key].status){
                                                newForm.observedParameter["option15"].enabled = true
                                                newForm.observedParameter["option16"].enabled = true
                                                newForm.observedParameter["option17"].enabled = true
                                                newForm.observedParameter["option18"].enabled = true
                                            }
                                            setForm(newForm)
                                        }}
                                        checked={form.observedParameter[key].status}
                                        disabled={!form.observedParameter[key].enabled}
                                        />
                                        
                                </div>    
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="boxes-form">
                <div className="d-flex justify-content-between">
                    <h3><b>Source</b></h3>
                    <div className="boxes-form__actions">
                    <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.source).map((key) => 
                                newForm.source[key].status = false
                            )
                            setForm(newForm)
                        }}>reset</span> | <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.source).map((key) => 
                                newForm.source[key].enabled ?
                                newForm.source[key].status = true :
                                newForm.source[key].status = false
                            )
                            setForm(newForm)
                    }}>select all</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {Object.keys(form.source).map((key) => {
                            return(
                                <div className="col-6">
                                    <Checkbox 
                                        label={{ children: form.source[key].title }} 
                                        onChange={() => {
                                            var newForm = {}
                                            newForm = Object.assign(newForm, form)
                                            newForm.source[key].status = !newForm.source[key].status
                                            if(["option1"].indexOf(key) != -1 && newForm.source[key].status){
                                                newForm.source["option2"].enabled = false
                                                newForm.source["option2"].status = false
                                            }
                                            else if(["option1"].indexOf(key) != -1 && !newForm.source[key].status){
                                                newForm.source["option2"].enabled = true
                                            }
                                            setForm(newForm)
                                        }}
                                        checked={form.source[key].status}
                                        disabled={!form.source[key].enabled}
                                        />
                                </div>    
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="boxes-form">
                <div className="d-flex justify-content-between">
                    <h3><b>Observation usage</b></h3>
                    <div className="boxes-form__actions">
                    <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observationUsage).map((key) => 
                                newForm.observationUsage[key].status = false
                            )
                            setForm(newForm)
                        }}>reset</span> | <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observationUsage).map((key) => 
                                newForm.observationUsage[key].enabled ?
                                newForm.observationUsage[key].status = true :
                                newForm.observationUsage[key].status = false
                            )
                            setForm(newForm)
                    }}>select all</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {Object.keys(form.observationUsage).map((key) => {
                            return(
                                <div className="col-6">
                                    <Checkbox 
                                        label={{ children: form.observationUsage[key].title }} 
                                        onChange={() => {
                                            var newForm = {}
                                            newForm = Object.assign(newForm, form)
                                            newForm.observationUsage[key].status = !newForm.observationUsage[key].status
                                            if(["option1","option2"].indexOf(key) != -1 && newForm.observationUsage[key].status){
                                                newForm.observationUsage["option3"].enabled = false
                                                newForm.observationUsage["option3"].status = false
                                            }
                                            else if(["option1","option2"].indexOf(key) != -1 && !newForm.observationUsage[key].status){
                                                newForm.observationUsage["option3"].enabled = true
                                            }
                                            setForm(newForm)
                                        }}
                                        checked={form.observationUsage[key].status}
                                        disabled={!form.observationUsage[key].enabled}
                                        />
                                </div>    
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="boxes-form">
                <div className="d-flex justify-content-between">
                    <h3><b>Observation events</b></h3>
                    <div className="boxes-form__actions">
                    <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observationEvents).map((key) => 
                                newForm.observationEvents[key].status = false
                            )
                            setForm(newForm)
                        }}>reset</span> | <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observationEvents).map((key) => 
                                newForm.observationEvents[key].enabled ?
                                newForm.observationEvents[key].status = true :
                                newForm.observationEvents[key].status = false
                            )
                            setForm(newForm)
                    }}>select all</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {Object.keys(form.observationEvents).map((key) => {
                            return(
                                <div className="col-6">
                                    <Checkbox 
                                        label={{ children: form.observationEvents[key].title }} 
                                        onChange={() => {
                                            var newForm = {}
                                            newForm = Object.assign(newForm, form)
                                            newForm.observationEvents[key].status = !newForm.observationEvents[key].status
                                            if(["option1","option2","option3","option4","option5","option6"].indexOf(key) != -1 && newForm.observationEvents[key].status){
                                                newForm.observationEvents["option15"].enabled = false
                                                newForm.observationEvents["option16"].enabled = false
                                                newForm.observationEvents["option17"].enabled = false
                                                newForm.observationEvents["option18"].enabled = false
                                                newForm.observationEvents["option15"].status = false
                                                newForm.observationEvents["option16"].status = false
                                                newForm.observationEvents["option17"].status = false
                                                newForm.observationEvents["option18"].status = false
                                            }
                                            else if(["option1","option2","option3","option4","option5","option6"].indexOf(key) != -1 && !newForm.observationEvents[key].status){
                                                newForm.observationEvents["option15"].enabled = true
                                                newForm.observationEvents["option16"].enabled = true
                                                newForm.observationEvents["option17"].enabled = true
                                                newForm.observationEvents["option18"].enabled = true
                                            }
                                            setForm(newForm)
                                        }}
                                        checked={form.observationEvents[key].status}
                                        disabled={!form.observationEvents[key].enabled}
                                        />
                                </div>    
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="boxes-form">
                <div className="d-flex justify-content-between">
                    <h3><b>Observation platform</b></h3>
                    <div className="boxes-form__actions">
                    <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observationPlatform).map((key) => 
                                newForm.observationPlatform[key].status = false
                            )
                            setForm(newForm)
                        }}>reset</span> | <span onClick={()=>{
                            var newForm = {}
                            newForm = Object.assign(newForm, form)
                            Object.keys(newForm.observationPlatform).map((key) => 
                                newForm.observationPlatform[key].status ?
                                newForm.observationPlatform[key].status = true :
                                newForm.observationPlatform[key].status = false
                            )
                            setForm(newForm)
                    }}>select all</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {Object.keys(form.observationPlatform).map((key) => {
                            return(
                                <div className="col-6">
                                    <Checkbox 
                                        label={{ children: form.observationPlatform[key].title }} 
                                        onChange={() => {
                                            var newForm = {}
                                            newForm = Object.assign(newForm, form)
                                            newForm.observationPlatform[key].status = !newForm.observationPlatform[key].status
                                            if(["option1","option2","option3","option4","option5","option6"].indexOf(key) != -1 && newForm.observationPlatform[key].status){
                                                newForm.observationPlatform["option8"].enabled = false
                                                newForm.observationPlatform["option8"].status = false
                                            }
                                            else if(["option1","option2","option3","option4","option5","option6"].indexOf(key) != -1 && !newForm.observationPlatform[key].status){
                                                newForm.observationPlatform["option8"].enabled = true
                                            }
                                            setForm(newForm)
                                        }}
                                        checked={form.observationPlatform[key].status}
                                        disabled={!form.observationPlatform[key].enabled}
                                        />
                                </div>    
                            )
                        })}
                    </div>
                </div>
                <div className="licence my-4 text-center"><span>Licence</span></div>
                <div className="container container--licence">
                    <div className="row">
                        {Object.keys(form.observationPlatformLicensed).map((key) => {
                            return(
                                <div className="col-6">
                                    <Checkbox
                                        label={{ children: form.observationPlatformLicensed[key].title }}
                                        onChange={() => {
                                            
                                        }}
                                        checked={false}
                                        disabled={true}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="boxes-form">
                <h3><b>Select the data format <span className="mandatory">*</span></b></h3>
                <div className="row">
                    {Object.values(item.encoding).map((itemEncoding, index) => (
                        <div className="col-md-6">
                            <CustomRadio
                                checked={encoding == index}
                                onChange={() => setEncoding(index)}
                                value={index}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            <label>{storeEncoding[itemEncoding].title}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="boxes-form">
                <h3><b>Select the request format <span className="mandatory">*</span></b></h3>
                <div className="row">
                    {Object.values(item.delivery).map((itemProvisioning, index) => (
                        <div className="col-md-6">
                            <CustomRadio
                                checked={provisioning == index}
                                onChange={() => setProvisioning(index)}
                                value={index}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            <label>{storeProvisioning[itemProvisioning].title}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className="boxes-form">
                <h3><b>Give a name to your request <span className="mandatory">*</span></b> (so you can later find it in your “Orders” area)</h3>
                <Input className={fieldEmpty ? 'my-border-danger': ''} onChange={handleChangeInput} placeholder={'request name'} value={requestName}/>
            </div>

            <div className="boxes-form no-border">
                <h3><b>Request your Data:</b></h3>
                {Object.values(item.access).map((itemAccess) =>{
                    if(storeAccess[itemAccess].code === 'public'){
                        let p = item.delivery[provisioning]
                        if(p === "direct"){
                            return (<RequestProcessed 
                                isLogged={isLogged}
                                form={form}
                                item={item}
                                provisioning={provisioning}
                                encoding={encoding}
                                version={"public"}
                                requestName={requestName}
                                title={storeAccess[itemAccess].title + ' version'}
                                fieldValidation={fieldValidation}
                            />)
                        }
                        else{
                            return (<RequestSuccessfully 
                                isLogged={isLogged}
                                form={form}
                                item={item}
                                provisioning={provisioning}
                                encoding={encoding}
                                version={"public"}
                                requestName={requestName}
                                title={storeAccess[itemAccess].title + ' version'}
                                fieldValidation={fieldValidation}
                            />)
                        }
                    } else{
                        return (<RequestToBasket
                            isLogged={isLogged}
                            form={form}
                            item={item}
                            provisioning={provisioning}
                            encoding={encoding}
                            version={storeAccess[itemAccess].code}
                            requestName={requestName}
                            title={storeAccess[itemAccess].title + ' version'}
                            fieldValidation={fieldValidation}
                        />)
                    }
                })
                }
                <p className="color-danger">{fieldEmpty? 'Please give a name to your request' : ''}</p>
            </div>

            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                    <Tooltip tooltip="normal" classes="Tooltip Tooltip-right me-3"/>
                    <h3 className="Title mt-0">Not sure about the licence request options?</h3>
                </div>
                <label className="Underline">Check the size of your request</label>
            </div>

        </Card>
         {!isLogged &&  <div className="download-data-login-banner"> <LoginBanner /> </div>}
        </>
    )

};

export default DownloadData;