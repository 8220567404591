import {Label} from "semantic-ui-react";
import {VerticalDots} from "../svg/VerticalDots";
import {Link} from "react-router-dom";

function OrderItem(props){
    let item = props.item
    console.log(item)
    return(
        <div className="order-item">
            <div className="row">
                <div className="col-md-1">3378</div>
                <div className="col-md-7"><strong>{item.orderName}</strong></div>
                <div className="col-md-1"><span><Label color={item.status == "Completed" ? 'blue':'orange'}>{item.status}</Label></span></div>
                <div className="col-md-1">a minute ago</div>
                <div className="col-md-1 order-item-svg position-relative"><VerticalDots/> 
                    <ul className="order-item__menu">
                        <li><Link to={{
                            pathname : '/order-details',
                            state : {item: item, index : props.index}
                        }}>View your order</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default OrderItem;