import '../search-form.css';
import './CheckboxFilters.css';
import { Checkbox, Popup } from 'semantic-ui-react';
import tooltip from '../../../icon/tooltip.png';
import { useDispatch, useSelector } from 'react-redux';
import { ARCHIVE } from "../../../constDefinition"
import { selectAccessARC, selectAccessRT } from '../../../actions';
import Tooltip from "../../modal/Tooltip";



function Access(props) {
    const dispatch = useDispatch()
    const store = useSelector(state => props.form === ARCHIVE ? state.archiveForm.accessArchive : state.realTimeForm.accessRealtime)

    if(store){
        if(props.view === "search"){
            return (
                <div className="SearchFormBox">
                    <div className="row">
                        <div className="col-md-10 ui checkbox Checkbox-box">
                        <div className="Checkbox-title">
                            <h2 className="Title">Access</h2>
                        </div>
                        {Object.values(store).map(item=>(
                            <div className="Checkbox-item" key={item.id}>
                                <Checkbox 
                                    label={{ children: item.title }} 
                                    onChange={() => props.form === ARCHIVE ? dispatch(selectAccessARC(item.code)) : dispatch(selectAccessRT(item.code))}
                                    checked={store[item.code] ? store[item.code].status : false}
                                    />
                            </div>
                        ))}
                        </div>
                        <div className="col-md-2 icon-info">
                            <Tooltip tooltip="normal" classes="Tooltip Tooltip-right"/>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            return (
                <div>
                    <div className="ui checkbox Checkbox-box">
                        {Object.values(store).map(item=>(
                            <div className="Checkbox-item" key={item.id}>
                                <Checkbox 
                                    label={{ children: item.title }} 
                                    onChange={() => props.form === ARCHIVE ? dispatch(selectAccessARC(item.code)) : dispatch(selectAccessRT(item.code))}
                                    checked={store[item.code] ? store[item.code].status : false}
                                    />
                            </div>
                        ))}
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <div class="p-2">
                            <Tooltip tooltip="normal" classes="Tooltip"/>
                        </div>
                    </div>
                </div>
            );
        }
    }
    else{
        return (
            <div></div>
        )
    }
}

export default Access; 