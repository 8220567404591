import React from "react";
import DetailsFooter from "../details/DetailsFooter";
import DetailsMain from "../details/DetailsMain";
import DetailsSidebar from "../details/DetailsSideBar";
function DetailsPage() {
    return (
        <React.Fragment>
          <div className="Page">
              <div className="row">
                  <DetailsMain />
                  <DetailsSidebar />
              </div>
          </div>
          <DetailsFooter />
        </React.Fragment>
    );
  }
  
  export default DetailsPage;
  