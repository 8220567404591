import { useSelector } from "react-redux";
import BasketItem from "../item-components/BasketItem";
import CreateOrder from "../item-components/CreateOrder";

function BasketPage() {
    var basket = useSelector(state => state.orders.basket)
    var orders = useSelector(state => state.orders)
    
    return (
        <div className="Page">
            <h1 className="mb-5">Your Basket</h1>

            {basket.map((item, index) => {
                return (<BasketItem item={item} index={index} header={'basket'}/>)
            }
            )}
            {basket && basket.length > 0 &&<CreateOrder />}
        </div>
    );
  }
  
  export default BasketPage;