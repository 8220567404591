import { GRID, COLUMN } from "../constDefinition"

const initialState = {
    resultVisualization : GRID
}

const resultVisualizationReducer = (state = initialState, action) =>{
    switch (action.type){
        case GRID:
            return {...state, resultVisualization : GRID};
        case COLUMN:
            return {...state, resultVisualization : COLUMN}
        default:
            return state;
    }
}

export default resultVisualizationReducer;