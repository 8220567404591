import React, { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import plus_cta from '../../icon/plus_cta.png'
import ParametersScroller from './ParametersScroller'
import InputIcon from "../../components/item-components/input-icon";
import TagLabel from '../item-components/TagLabel';
import { useDispatch } from 'react-redux';
import { setParametersArchive, setParametersRealTime } from '../../actions';
import { ARCHIVE } from '../../constDefinition';
import ParametersScrollerReadOnly from './ParametersScrollerReadOnly';

function ParametersModal(props) {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  
  const [pageNumber, setPageNumber] = useState(0)
  const [query, setQuery] = useState("")

  const [items, setItems] = useState({})
  const [checkboxSelected, setCheckboxSelected] = useState({})
  
  function handleSearch(e) {
    //if(e.target.value.length >= 3 || e.target.value.length==0){
      setQuery(e.target.value)
      setPageNumber(0)
    //}
  }  

  function resetSettings(){
    setPageNumber(0)
    setQuery("")
    setCheckboxSelected({})
  }

  function pageNumberHandlerCallback(num){
    setPageNumber(num)
  }

  function checkboxSelectCallback(state, items){
    setCheckboxSelected(state)
    setItems(items)
  }

  function onCloseModal(){
    setOpen(false)
    resetSettings()
  }

  function onOpenModal(){
    setOpen(true)
  }

  function removeCallback(item){
    var checkboxes = checkboxSelected
    checkboxes[item['id']] = false
    setCheckboxSelected({})
  }

  return (
    <Modal
      onClose={() => onCloseModal()}
      onOpen={() => onOpenModal()}
      open={open}
      trigger={
        props.readOnly?
        <label className="Underline">See all</label>
        : 
        <div className={props.view == "search" ? "ParametersActionItems" : "IconLabelStart"}>
          <img src={plus_cta} alt='add parameters'></img>
          <label className="Underline">See all the available parameters</label>
        </div>
      }
    >
      <Modal.Header>
        <div className="row">
          <div className="col-md-6">
            Parameters
          </div>
          {!props.readOnly &&
            <div className="col-md-6">
              <InputIcon onChange={handleSearch} placeholder={'Search for parameters'}/>
            </div>
          }
        </div>
        {!props.readOnly &&
          <div className="tag-container">
              {Object.keys(checkboxSelected).map(function(key){
                if(checkboxSelected[key]){
                  return (
                    <TagLabel removeCallback={removeCallback} popup={items[key].name} title={items[key].shortname} key={key} item={items[key]}></TagLabel>
                    )
                }
              })}
          </div>
        }
      </Modal.Header>
      <Modal.Content image scrolling>
        
        <Modal.Description>
          {props.readOnly ?
          <ParametersScrollerReadOnly items={props.items}></ParametersScrollerReadOnly>
            : <ParametersScroller form={props.form} query={query} pageNumber={pageNumber} checkboxSelect={checkboxSelectCallback} pageNumberHandler={pageNumberHandlerCallback}></ParametersScroller>
          }
        </Modal.Description>
      </Modal.Content>

      {
        props.readOnly ?
        <Modal.Actions>
          <Button 
            primary
            onClick={() => {
              setOpen(false); 
            }}>
              Ok
          </Button>
        </Modal.Actions>

        :<Modal.Actions>
          <Button 
            secondary
            onClick={() => {
              setOpen(false); 
              resetSettings()
            }}>
              Cancel
          </Button>
          <Button
            content="Add to search"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {
              setOpen(false)
              dispatch(props.form === ARCHIVE ? setParametersArchive(items, checkboxSelected): setParametersRealTime(items, checkboxSelected))
              resetSettings()
            }}
            primary
          />
        </Modal.Actions>
    }
    </Modal>
  )
}

export default ParametersModal