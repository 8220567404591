import React from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { periodSlider } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';



const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
  },
  margin: {
    height: theme.spacing(3),
  },
}));


function valuetext(value) {
  return `${value}`;
}


const CustomSlider = withStyles({
  root: {
    color: '#3855B3',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#3855B3',
    marginTop: -6,
    marginLeft: -6,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#3855B3',
    },
  },
  track: {
    height: 5,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  
})(Slider);


function PeriodSlider() {
  const dispatch = useDispatch()
  const classes = useStyles();
  var valueSlider = useSelector(state => state.archiveForm.periodSlider)
  valueSlider = valueSlider.map(value => parseInt(value))
  var defaultValueSlider = useSelector(state => state.archiveForm.defaultPeriodSlider)
  defaultValueSlider = defaultValueSlider.map(value => parseInt(value))
  

  const handleChange = (event, newValue) => {
    dispatch(periodSlider(newValue))
  }

  return (
    <div className={classes.root}>
      <CustomSlider 
        defaultValue={valueSlider}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext} 
        min={defaultValueSlider[0]}
        max={defaultValueSlider[1]}
        value={valueSlider}
        onChange={handleChange}/>
    </div>
  );
}

export default PeriodSlider;