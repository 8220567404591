import './Overview.css'
import { useSelector } from 'react-redux';
import periodIcon from '../../icon/periodIcon.png';
import parametersIcon from '../../icon/parametersIcon.png';
import provisioningIcon from '../../icon/provisioningIcon.png';
import formatdataIcon from '../../icon/formatdataIcon.png';
import tooltipPrimary from '../../icon/tooltipPrimary.png'
import Card from "./Card";
import { ARCHIVE } from '../../constDefinition';
import  Moment  from 'react-moment';
import { Icon, Label, Popup } from 'semantic-ui-react'
import Tooltip from "../modal/Tooltip";

function Overview(props) {
    const isLogged = useSelector(state => state.isLogged.logged)
    let item = useSelector(state => state.datasetDetails.dataset);
    var nature = item ?item.nature[0]:ARCHIVE
    const store = useSelector(state => nature === ARCHIVE ? state.archiveForm : state.realTimeForm)
    const storeAccess = useSelector(state => nature === ARCHIVE ? state.archiveForm.accessArchive : state.realTimeForm.accessRealtime)
    var provisioningLabel
    var encodingLabel
    if(nature === ARCHIVE){
        if(item){
            item.delivery.map(delivery => {
                if(provisioningLabel){
                    provisioningLabel = provisioningLabel +", "+store.provisioningArchive[delivery].title
                }
                else{
                    provisioningLabel = store.provisioningArchive[delivery].title
                }
            })
            item.encoding.map(encoding => {
                if(encodingLabel){
                    encodingLabel = encodingLabel +", "+store.formatDataArchive[encoding].title
                }
                else{
                    encodingLabel = store.formatDataArchive[encoding].title
                }
            })
        }
    }
    else{
        if(item){
            item.delivery.map(delivery => {
                if(provisioningLabel){
                    provisioningLabel = provisioningLabel +", "+store.provisioningRealtime[delivery].title
                }
                else{
                    provisioningLabel = store.provisioningRealtime[delivery].title
                }
            })
            item.encoding.map(encoding => {
                if(encodingLabel){
                    encodingLabel = encodingLabel +", "+store.formatDataRealtime[encoding].title
                }
                else{
                    encodingLabel = store.formatDataRealtime[encoding].title
                }
            })
        }
    }

    function getColor(alternative){
        console.log(alternative)
        if(alternative.code == 'public'){
            return 'available'
        }
        else if(!isLogged){
            return 'disabled'
        }
        else{
            return 'unavailable'
        }
    }

    return (
        <Card>
            <div style={{padding: "20px"}}>
                <p>
                    {item ? item.description: ""}
                </p>
                <div style={{marginTop: "40px", marginBottom: "40px"}}>
                    {
                        nature === ARCHIVE ?
                            <div class="d-flex flex-row">
                                <div class="p-2 ResultsIcon"><img src={periodIcon} alt='info' className=""></img></div>
                                <div class="p-2">Period:</div>
                                <div class="p-2"><b>From <Moment format="MMMM YYYY">{item? item.start_date: ""}</Moment> to <Moment format="MMMM YYYY">{item ? item.end_date : ""}</Moment></b></div>
                            </div>
                            : <div></div>
                    }
                    <div className="d-flex flex-row">
                        <div className="p-2 ResultsIcon"><img src={parametersIcon} alt='info' className=""></img></div>
                        <div className="p-2">Parameters:</div>
                        <div class="p-2">
                            {
                            item && item.variables.map(
                                variable => {
                                    return <Popup content={variable.name} trigger={
                                    <span>{variable.shortname},    </span>
                                    } />
                                }
                            )
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="p-2 ResultsIcon"><img src={provisioningIcon} alt='info' className=""></img></div>
                        <div className="p-2">Provisioning:</div>
                        <div className="p-2"><b>{provisioningLabel}</b></div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="p-2 ResultsIcon"><img src={formatdataIcon} alt='info' className=""></img></div>
                        <div className="p-2">Format of Data:</div>
                        <div className="p-2"><b>{encodingLabel}</b></div>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="p-2"><b>Type:</b></div>
                    <div className="p-2">{item ? item.type ? item.type: "N/A" : ""}</div>
                </div>
                <div className="d-flex flex-row">
                    <div className="p-2"><b>Created by:</b></div>
                    <div className="p-2">{item ? item.created_by ? item.created_by: "N/A" : ""}</div>
                </div>
                <div className="d-flex flex-row">
                    <div className="p-2"><b>Provided by:</b></div>
                    <div className="p-2">{item ? item.provided_by ? item.provided_by: "N/A" : ""}</div>
                </div>
                <div className="overview-footer">
                    <div className="divider"><span>ACCESS TYPE</span></div>
                    <div className="row">
                        <div className="d-flex flex-row justify-content-start">
                            {item ? item.access.map(access => {
                                    var accessLabel = storeAccess ? storeAccess[access].title: ""
                                    var enabled = storeAccess ? getColor(storeAccess[access]) : "disabled"

                                    return (
                                        <div className="col-md-4 IconLabelStart me-3">
                                            <Tooltip tooltip="primary" classes=""/>
                                            <label className={enabled}>{accessLabel}</label>
                                        </div>
                                    )
                                
                            }): <></>}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )

};

export default Overview;
