import { ARCHIVE, REALTIME, ARCHIVE_TAB_SELECT, REALTIME_TAB_SELECT } from "../constDefinition";


const activeTabReducer = (state = REALTIME, action) => {
    switch(action.type) {
        case REALTIME_TAB_SELECT:
            state = REALTIME;
            return state;
        case ARCHIVE_TAB_SELECT:
            state = ARCHIVE;
            return state;
        default:
            return state;
    }
}

export default activeTabReducer;