import '../search-form.css';
import useStyles from '../../item-components/accordionSidebarStyle';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ParametersModal from '../../modal/ParametersModal'
import { useDispatch, useSelector } from 'react-redux';
import { ARCHIVE } from '../../../constDefinition';
import TagLabel from '../../item-components/TagLabel';
import reset_cta from '../../../icon/reset_cta.png'
import { removeAllParameterArchive, removeAllParameterRealTime, removeParameterArchive, removeParameterRealTime } from '../../../actions';
import SearchParameters from '../../item-components/SearchParameters';

function Parameters(props) {
    const dispatch = useDispatch()
    const store = useSelector(state => props.form === ARCHIVE ? state.archiveForm.parameters : state.realTimeForm.parameters)
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = () => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
      };

    function removeParametersFromStore(){
        props.form === ARCHIVE ? dispatch(removeAllParameterArchive()) : dispatch(removeAllParameterRealTime())
    }

    function removeCallback(item){
        if(props.form === ARCHIVE){
            dispatch(removeParameterArchive(item))
        }
        else{
            dispatch(removeParameterRealTime(item))
            
        }
    }

    if(props.view === "search"){
        return (
            <div className="FormField">
                <div className="SearchFormBox ">
                    <SearchParameters form={props.form}></SearchParameters>
                    <div className="tag-container">
                        {store.map(function(item){
                            return (
                                <TagLabel popup={item.name} removeCallback={removeCallback} title={item.shortname} key={item.id} item={item}></TagLabel>
                                )
                        })}
                    </div>
                    <div className="row ParametersAction">
                        <div className="col-md-6 col-sm-12">
                            Maecenas sed diam eget risus varius blandit sit amet non magna.
                        </div>
                        <div className="col-md-4 col-sm-6 ParametersActionItems Pointer">
                            <ParametersModal readOnly={false} view={props.view} form={props.form}></ParametersModal>
                        </div>
                        <div className="col-md-2 col-sm-6 ParametersActionItems Pointer" onClick={removeParametersFromStore}>
                            <img src={reset_cta} alt='remove parameters'></img>
                            <label className="Red">Reset</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else{
        return (
            <div className={classes.root, "AccordionSidebar"}>
                <Accordion expanded={expanded} onChange={handleChange()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}><h3 className="Title">Parameters</h3></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width:"100%"}}>
                        
                        <SearchParameters form={props.form}></SearchParameters>
                        <div className="tag-container">
                            {store.map(function(item){
                                return (
                                    <TagLabel popup={item.name} removeCallback={removeCallback} title={item.shortname} key={item.id} item={item}></TagLabel>
                                    )
                            })}
                        </div>
                        <div className="row ParametersAction">
                            <div className="col-8 Pointer">
                                <ParametersModal readOnly={false} view={props.view} form={props.form}></ParametersModal>
                            </div>
                            <div className="col-4 Pointer" onClick={removeParametersFromStore}>
                                <div className="IconLabel">
                                    <img src={reset_cta} alt='remove parameters'></img>
                                    <label className="Red">Reset</label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </AccordionDetails>
                </Accordion>
        
            </div>
        );
    }
  }
  
  export default Parameters;
  