import './BasketItem.css'
import { Button, Input } from 'semantic-ui-react';
import {Link} from "react-router-dom";
import {addBasketName, setDetailsDataset} from "../../actions";
import {useDispatch, useSelector} from 'react-redux'
import { useState } from 'react';
function CreateOrder(){
    const dispatch = useDispatch();
    const [basketName, setOrderName] = useState('')
    const basket = useSelector(state => state.orders.basket)
    
    const [fieldEmpty, handlerFieldEmpty] = useState(false)

    const handleChangeInput = (e) => {
        setOrderName(e.target.value)
        handlerFieldEmpty(false)
    }
    const fieldValidation = (name)=>{
        handlerFieldEmpty(!fieldEmpty)
    }

    return(
        <div className="create-order">
            <h3><strong>Give a name to your order</strong> <span className="text-danger"> * </span> <span className="small"> (so you can later find it in your “Orders” area)</span></h3>

            <Input className={fieldEmpty ? 'my-border-danger': ''} onChange={handleChangeInput} placeholder={'request name'} value={basketName}/>

            <p className="color-danger">{fieldEmpty? 'Please give a name to your order' : ''}</p>
            <div className="text-end mt-3">
                <Link to="/checkout">
                    <Button 
                        disabled={basketName === ''}
                        onClick={()=>{
                        console.log(basketName)
                        if(basketName === ''){
                            fieldValidation()
                            return
                        }
                        else{
                            dispatch(addBasketName(basketName))
                        }
                        }}> 
                        Create order
                    </Button>
                </Link>
            </div>
        </div>
    );
}
export default CreateOrder;