import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Table } from 'semantic-ui-react';
import { ARCHIVE } from "../../constDefinition";
import ParametersManager from "./ParametersManager";

function ParametersScroller(props){

    const store = useSelector(state => props.form === ARCHIVE ? state.archiveForm.parameters : state.realTimeForm.parameters)

    const [checkboxSelected, setCheckboxSelected] = useState(getInitialCheckboxSelected())

    const {
      loading,
      error,
      items,
      hasMore
    } = ParametersManager(props.query, props.pageNumber)
    

    const observer = useRef()
    const lastItemElementRef = useCallback(node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          props.pageNumberHandler(prevPageNumber => prevPageNumber + 25)
        }
      })
      if (node) observer.current.observe(node)
    }, [loading, hasMore])

    if(items && Object.keys(items).length > 0){
        props.checkboxSelect(checkboxSelected, items)
    }


    function getInitialCheckboxSelected(){
        var checkboxes = {}
        store.map(function(item){
            checkboxes[item.id] = true
        })
        return checkboxes
    }

    return (
        <div>
            <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Short Name</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(items).map(function(key, index){
                    var item = items[key]
                    if (Object.keys(items).length === index + 1) {
                        return (
                            
                                <Table.Row>
                                    <Table.Cell>
                                        <div ref={lastItemElementRef} key={item.id}>
                                            <Checkbox
                                                className="col-md-1"
                                                checked={checkboxSelected[item.id]}
                                                onChange={() => {
                                                    var id = item.id
                                                    setCheckboxSelected(checkboxSelected[id] ? {...checkboxSelected, [id] : !checkboxSelected[id]} : {...checkboxSelected, [id] : true} );
                                                    props.checkboxSelect(checkboxSelected[id] ? {...checkboxSelected, [id] : !checkboxSelected[id]} : {...checkboxSelected, [id] : true}, items );
                                                }}
                                                ></Checkbox>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.shortname}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.description}
                                    </Table.Cell>
                                </Table.Row>
                        )
                    } else {
                        return (
                            
                                <Table.Row >
                                    <Table.Cell>
                                        <div ref={lastItemElementRef} key={item.id}>
                                            <Checkbox
                                                checked={checkboxSelected[item.id]}
                                                onChange={() => {
                                                    var id = item.id
                                                    setCheckboxSelected(checkboxSelected[id] ? {...checkboxSelected, [id] : !checkboxSelected[id]} : {...checkboxSelected, [id] : true} ); 
                                                    props.checkboxSelect(checkboxSelected[id] ? {...checkboxSelected, [id] : !checkboxSelected[id]} : {...checkboxSelected, [id] : true}, items );
                                                }}
                                                ></Checkbox>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.shortname}
                                    </Table.Cell>
                                    <Table.Cell>
                                            {item.description}
                                    </Table.Cell>
                                </Table.Row>
                            
                        )
                    }
                    })}
                    
                <div>{loading && 'Loading...'}</div>
                <div>{error && 'Error'}</div>
            </Table.Body>
            </Table>
        </div>
    );
}


export default ParametersScroller;
