import React from "react";
import { useSelector } from "react-redux";
import { ARCHIVE } from "../../constDefinition";
function  ProductContent(props){
    var item = props.item ? props.item : '';
    var nature = item.dataset ?item.dataset.nature[0]:ARCHIVE
    const storeProvisioning = useSelector(state => nature === ARCHIVE ? state.archiveForm.provisioningArchive : state.realTimeForm.provisioningRealtime)
    const storeEncoding = useSelector(state => nature === ARCHIVE ? state.archiveForm.formatDataArchive : state.realTimeForm.formatDataRealtime)

    var observedParameterOptions = "";
    Object.keys(item.selection["observedParameter"]).map(
        key => {
            if(observedParameterOptions == item.selection["observedParameter"][key].status){
                if(!observedParameterOptions){
                    observedParameterOptions = item.selection["observedParameter"][key].title
                }
                else{
                    observedParameterOptions = observedParameterOptions +", "+item.selection["observedParameter"][key].title
                }
            }
        }
    )

    var sourceOptions = ""
    Object.keys(item.selection["source"]).map(
        key => {
            if(sourceOptions == item.selection["source"][key].status){
                if(!sourceOptions){
                    sourceOptions = item.selection["source"][key].title
                }
                else{
                    sourceOptions = sourceOptions +", "+item.selection["source"][key].title
                }
            }
        }
    )

    var observationUsageOptions = ""
    Object.keys(item.selection["observationUsage"]).map(
        key => {
            if(observationUsageOptions == item.selection["observationUsage"][key].status){
                if(!observationUsageOptions){
                    observationUsageOptions = item.selection["observationUsage"][key].title
                }
                else{
                    observationUsageOptions = observationUsageOptions +", "+item.selection["observationUsage"][key].title
                }
            }
        }
    )

    var observationEventsOptions = ""
    Object.keys(item.selection["observationEvents"]).map(
        key => {
            if(observationEventsOptions == item.selection["observationEvents"][key].status){
                if(!observationEventsOptions){
                    observationEventsOptions = item.selection["observationEvents"][key].title
                }
                else{
                    observationEventsOptions = observationEventsOptions +", "+item.selection["observationEvents"][key].title
                }
            }
        }
    )
    var observationPlatformOptions = ""
    Object.keys(item.selection["observationPlatform"]).map(
        key => {
            if(observationPlatformOptions == item.selection["observationPlatform"][key].status){
                if(!observationPlatformOptions){
                    observationPlatformOptions = item.selection["observationPlatform"][key].title
                }
                else{
                    observationPlatformOptions = observationPlatformOptions +", "+item.selection["observationPlatform"][key].title
                }
            }
        }
    )

    var provisioning = item.selection.provisioning
    var encoding = item.selection.encoding


    return(
        <React.Fragment>
            <h2><strong>{item.requestName}</strong></h2>
            <p>{item.dataset.title}</p>
            <div className="row mt-5">
                <div className="col-md-2">
                    <p><strong>Observed parameter</strong></p>
                    <p><strong>Source</strong></p>
                    <p><strong>Observation usage</strong></p>
                    <p><strong>Observation events</strong></p>
                    <p><strong>Observation platform</strong></p>
                    <p><strong>Format of data</strong></p>
                    <p><strong>Request format</strong></p>
                </div>
                <div className="col-md-10">
                    <p>{observedParameterOptions}</p>
                    <p>{sourceOptions}</p>
                    <p>{observationUsageOptions}</p>
                    <p>{observationEventsOptions}</p>
                    <p>{observationPlatformOptions}</p>
                    <p>{storeEncoding[encoding].title}</p>
                    <p>{storeProvisioning[provisioning].title}</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductContent;