import './TopBar.css';
import NavTools from './NavTools';

function TopBar(props) {
    return (
        <div className="TopBar">
            <NavTools tab={props.tab}/>
        </div>
    );
}
  
export default TopBar;
  