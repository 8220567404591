import { 
    ACCESS_REALTIME, 
    ACCESS_CHECKBOXES_REALTIME,
    PROVISIONING_CHECKBOXES_REALTIME,
    PROVISIONING_REALTIME,
    FORMAT_DATA_CHECKBOXES_REALTIME,
    FORMAT_DATA_REALTIME,
    PARAMETERS_SELECT_REALTIME,
    PARAMETERS_REMOVE_REALTIME,
    DATASETS_SELECT_REALTIME,
    DATASETS_REMOVE_REALTIME,
    PARAMETERS_REMOVE_ALL_REALTIME,
    DATASETS_REMOVE_ALL_REALTIME,
    DATASETS_ADD_REALTIME,
    PARAMETERS_ADD_REALTIME,
    SEARCH_REALTIME,
    REALTIME_KEYWORD_SELECT,
    RESET_REALTIME,
    START_LOADING_REALTIME,
    CHANGE_PAGE_REALTIME
} from "../constDefinition"

const initialState = {
    keyword: "",
    parameters: [],
    accessRealtime: {},
    provisioningRealtime: {},
    formatDataRealtime: {},
    results: [],
    count: 0,
    page: 1,
    loading: false
}

function resetCheckboxex(checkboxes){
    for (const [key, value] of Object.entries(checkboxes)) {
        value.status = false
      }
    var editedCheckboxes = {}
    editedCheckboxes = Object.assign(editedCheckboxes, checkboxes);
    return editedCheckboxes
      
}

const realTimeFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case START_LOADING_REALTIME:
            return {...state, loading: true}
        case RESET_REALTIME:
            return { 
                ...state, 
                keyword: '', 
                parameters: [], 
                accessRealtime: resetCheckboxex(state.accessRealtime),
                provisioningRealtime: resetCheckboxex(state.provisioningRealtime),
                formatDataRealtime: resetCheckboxex(state.formatDataRealtime),
            }

        case REALTIME_KEYWORD_SELECT:
            state.keyword = action.value
            return state

        case ACCESS_CHECKBOXES_REALTIME:
            if(action.value){
                var accessRealtime = {}
                state.accessRealtime = {}
                accessRealtime = Object.assign(accessRealtime, state.accessRealtime);
                action.value.forEach(checkbox => {
                    var accessCheckbox = {}
                    accessCheckbox = Object.assign(accessCheckbox, checkbox)
                    accessCheckbox["status"] = false
                    state.accessRealtime[accessCheckbox.code] = accessCheckbox
                });
            }
            return state;


        case ACCESS_REALTIME:
            if(state.accessRealtime[action.code]){
                var accessRealtime = {}
                accessRealtime = Object.assign(accessRealtime, state.accessRealtime);
                accessRealtime[action.code]["status"] = !accessRealtime[action.code]["status"]
                state.accessRealtime =  accessRealtime;
            }
            return state

        case PROVISIONING_CHECKBOXES_REALTIME:
            if(action.value){
                var provisioningRealtime = {}
                state.provisioningRealtime = {}
                provisioningRealtime = Object.assign(provisioningRealtime, state.provisioningRealtime);
                action.value.forEach(checkbox => {
                    var provisioningCheckbox = {}
                    provisioningCheckbox = Object.assign(provisioningCheckbox, checkbox)
                    provisioningCheckbox["status"] = false
                    state.provisioningRealtime[provisioningCheckbox.code] = provisioningCheckbox
                });
            }
            return state;


        case PROVISIONING_REALTIME:
            if(state.provisioningRealtime[action.code]){
                var provisioningRealtime = {}
                provisioningRealtime = Object.assign(provisioningRealtime, state.provisioningRealtime);
                provisioningRealtime[action.code]["status"] = !provisioningRealtime[action.code]["status"]
                state.provisioningRealtime = provisioningRealtime
            }
            return state

        case FORMAT_DATA_CHECKBOXES_REALTIME:
            if(action.value){
                var formatDataRealtime = {}
                state.formatDataRealtime = {}
                formatDataRealtime = Object.assign(formatDataRealtime, state.formatDataRealtime);
                action.value.forEach(checkbox => {
                    var formatDataRealTimeCheckbox = {}
                    formatDataRealTimeCheckbox = Object.assign(checkbox, formatDataRealTimeCheckbox)
                    formatDataRealTimeCheckbox["status"] = false
                    formatDataRealtime[formatDataRealTimeCheckbox.code] = formatDataRealTimeCheckbox
                });
                state.formatDataRealtime = formatDataRealtime
            }
            return state;


        case FORMAT_DATA_REALTIME:
            if(state.formatDataRealtime[action.code]){
                var formatDataRealtime = {}
                formatDataRealtime = Object.assign(formatDataRealtime, state.formatDataRealtime);
                formatDataRealtime[action.code]["status"] = !formatDataRealtime[action.code]["status"]
                state.formatDataRealtime = formatDataRealtime
            }
            return state
        
        case PARAMETERS_ADD_REALTIME:
            if(state.parameters.indexOf(action.item) == -1){
                return {
                    ...state,
                    parameters: [...state.parameters, action.item]
                }
            }
            return state

        case PARAMETERS_SELECT_REALTIME:
            var items = action.items
            var checkboxSelected = action.options
            var parameters = Object.keys(checkboxSelected).map(function(key){
                if(checkboxSelected[key]){
                    return Object.assign({}, items[key])
                }
                })
            parameters = parameters.filter(function (obj) {
                return obj !== undefined
            })
            state.parameters = []
            state.parameters = Object.assign(state.parameters, parameters)
            return state
        
        case PARAMETERS_REMOVE_REALTIME:
            var item = action.item
            var parameters = state.parameters.filter(function( obj ) {
                return obj.id !== item.id;
            });
            state.parameters = []
            state.parameters = Object.assign(state.parameters, parameters)
            return state

        case PARAMETERS_REMOVE_ALL_REALTIME:
            state.parameters = []
            return state

        case DATASETS_ADD_REALTIME:
            if(state.datasets.indexOf(action.item) == -1){
                return {
                    ...state,
                    datasets: [...state.datasets, action.item]
                }
            }
            return state

        case DATASETS_SELECT_REALTIME:
            var items = action.items
            var checkboxSelected = action.options
            var datasets = Object.keys(checkboxSelected).map(function(key){
                if(checkboxSelected[key]){
                    return Object.assign({}, items[key])
                }
                })
                datasets = datasets.filter(function (obj) {
                return obj !== undefined
            })
            state.datasets = []
            state.datasets = Object.assign(state.datasets, datasets)
            return state
        
        case DATASETS_REMOVE_REALTIME:
            var item = action.item
            var datasets = state.datasets.filter(function( obj ) {
                return obj.id !== item.id;
            });
            state.datasets = []
            state.datasets = Object.assign(state.datasets, datasets)
            return state

        case DATASETS_REMOVE_ALL_REALTIME:
            state.datasets = []
            return state

        case SEARCH_REALTIME:
            state.results = []
            state.results = Object.assign([], action.results.results)
            state.count = action.results.count
            state.loading = false
            return state

        case CHANGE_PAGE_REALTIME:
            return {...state, page: action.value}

        default:
            return state;
    }
}

export default realTimeFormReducer;