
import { ARCHIVE, DOCUMENTATION, DOWNLOAD_DATA, OVERVIEW } from '../../constDefinition';
import { useSelector } from 'react-redux';
import Overview from './Overview';
import Documentation from './Documentation';
import DownloadData from './DownloadData';


function DetailsBody(props) {
    var store = useSelector(state => state.datasetDetails.datasetDetailsActiveTab)
    switch(store){
        case OVERVIEW:
            return <Overview></Overview>
        case DOWNLOAD_DATA:
            return <DownloadData></DownloadData>
        case DOCUMENTATION:
            return <Documentation></Documentation>
        default:
            return <></>
    }
};

export default DetailsBody;