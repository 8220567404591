import '../item-components/BasketItem.css'
import CheckoutItem from "../item-components/CheckoutItem";
import BannerPrice from "../item-components/BannerPrice";
import PriceInfo from "../item-components/PriceInfo";
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {submitOrder} from "../../actions";

function CheckoutPage() {
    const dispatch = useDispatch();
    var basket = useSelector(state => state.orders.basket)
    let basketName = useSelector(state => state.orders.basketName)
    let checkoutItem = basket.map( (order, index) => <CheckoutItem item={order} index={index}/>)
   
    return (
        <div className="Page">
            <h1 className="mb-5"><strong>Your Order</strong> {basketName}</h1>
            <p>
                Click on the following link to read about data <a href="#" target="_blank">pricing and discounts</a>. <br/>
                <strong>Requested Small Business Discount</strong>: No <br/>
                <strong>Requested research licence</strong>: No <br/>
                <strong>Reference number</strong>: 3377 <br/>
            </p>

            {checkoutItem ? checkoutItem : ''}
            <BannerPrice />
            <PriceInfo />
            <div className="d-flex justify-content-between my-5">
                <Button basic color='blue'>Download PDF</Button>
                <Link to="/orders">
                    <Button primary onClick={()=>{
                        dispatch(submitOrder(basketName,basket, 'Completed'))
                    }}>
                        Go to payment
                    </Button>
                </Link>
            </div>
            <hr className="mb-4"/>
            <p><strong>Further charges, such as a set-up fee, may be applicable for new licence holders. For data delivery and licences with ECMWF, the one-off set-up fee is €2000.
                Other Licensors may apply different charges. For more information, please see Article 32 of this document.</strong></p>

            <p><strong>Quotes created in 2020 will be valid until 31st December 2020 at a rate of EPU = 0.6. Quotes created in 2021 will be valid for 90 days at a rate of EPU = 0.5.</strong></p>

            <p><strong>Research licence and small business discount requests can be submitted via this application during the final submission step.</strong></p>
        </div>
    );
  }
  
  export default CheckoutPage;
  