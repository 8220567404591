//import ChatBot from 'react-simple-chatbot';
import { useSelector } from 'react-redux';
import DetailsHeader from "./DetailsHeader";
import DetailsBody from './DetailsBody';

function DetailsMain(props) {

    var store = useSelector(state => state.datasetDetails.dataset)


    return (
        <div className="col-md-8" style={{ paddingRight : 25+ "px" }}>
            {store && <DetailsHeader title={store.title}/>}
            <DetailsBody></DetailsBody>

        </div>
    )

};

export default DetailsMain;